import React from "react";
import "./servicesSubmenu.css";
import { ReactComponent as ArrowMenu } from "../../../assets/svg/arrow-right-submenu.svg";
import { Link } from "react-router-dom";

function ServicesSubmenu() {
  return (
    <div className="submenu_servicesMain">
      <div className="submenu_contain_services">
        <div className="submenu_info">
          <p className="submenu_services">OUR Services</p>
          <p className="submenu_discover">Discover the Power of Data and Technology for Your Long-term Success</p>
          <p className="submenu_help">
            Phoenix uses data and technology to help clients build trust and achieve long-term success by transforming, growing, and operating effectively.
          </p>
        </div>

        <div className="submenu_services_contain">
          <Link to="/devops" className="submenu_services_link">
            DevOps
            <ArrowMenu />
          </Link>
          <Link to="/S/4hana" className="submenu_services_link">
            SAP 4/HANA
            <ArrowMenu />
          </Link>
          <Link to="/sap-SuccessFactors" className="submenu_services_link">
            SAP SuccessFactors
            <ArrowMenu />
          </Link>
        </div>
        {/* container 2 is hidden */}
        <div className="submenu_services_contain">
          {" "}
          <Link to="/DigitalMarketing" className="submenu_services_link">
            Digital Marketing
            <ArrowMenu />
          </Link>
          <Link to="/sap-ariba" className="submenu_services_link">
            SAP Ariba
            <ArrowMenu />
          </Link>
          <Link to="/Sap-Rise" className="submenu_services_link">
            RISE with SAP
            <ArrowMenu />
          </Link>
        </div>
        {/* container 2 is hidden */}
      </div>
    </div>
  );
}

export default ServicesSubmenu;
