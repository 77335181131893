import React, { useState, useEffect, useRef } from "react";
import "./HomePartFiveInfo.css";
import HomeP5Client from "../../../assets/images/Home_P5_Client.jpg";
import HomeP5Excellence from "../../../assets/images/Home_P5_Excellence.jpg";
import HomeP5Innovation from "../../../assets/svg/Home_P5_Innovation.svg";
import HomeP5Adaptability from "../../../assets/images/Home_P5_Adaptability.jpg";
import HomeP5Integrity from "../../../assets/svg/Home_P5_Integrity.svg";
import HomeP5Social from "../../../assets/svg/Home_P5_Social.svg";
import HomeP5Collaboration from "../../../assets/images/Home_P5_Collaboration.jpg";
import HomeP5Info from "../../../assets/images/HomeP5_Info.png";
import LazyMedia from "../../../lazyLoad/lazyLoad";

function HomePartFiveInfo() {
  const [activeButton, setActiveButton] = useState(0);
  const intervalRef = useRef(null);

  const HomePartFiveInfoContent = [
    {
      id: "AwsOne",
      name: "Client-Centric",
      h3: "Client-Centric",
      paragraph: [
        "Our clients are our top priority. We are dedicated to understanding their unique needs and challenges, tailoring our solutions to meet their specific requirements, and providing unwavering support throughout their journey.",
      ],
      img: HomeP5Client,
      imgRotate: HomeP5Info,
    },
    {
      id: "AwsTwo",
      name: "Excellence",
      h3: "Excellence",
      paragraph: [
        "We are committed to delivering excellence in all our services. Our team of experts goes above and beyond to provide solutions that exceed expectations, ensuring the success of our clients.",
      ],
      img: HomeP5Excellence,
      imgRotate: HomeP5Info,
    },
    {
      id: "AwsThree",
      name: "Innovation",
      h3: "Innovation",
      paragraph: [
        "We guide you through the digital landscape, helping your organization harness the power of modern technologies to enhance efficiency and drive growth.",
      ],
      img: HomeP5Innovation,
      imgRotate: HomeP5Info,
    },
    {
      id: "AwsFour",
      name: "Adaptability",
      h3: "Adaptability",
      paragraph: [
        "In the ever-evolving digital landscape, adaptability is crucial. We remain agile and open to change, ready to pivot and adjust our strategies to keep pace with emerging technologies and market shifts.",
      ],
      img: HomeP5Adaptability,
      imgRotate: HomeP5Info,
    },
    {
      id: "AwsFive",
      name: "Integrity",
      h3: "Integrity",
      paragraph: ["We strive to reflect our commitment to ethics & core principles of honesty and trustworthiness in everything we do."],
      img: HomeP5Integrity,
      imgRotate: HomeP5Info,
    },
    {
      id: "AwsSix",
      name: "Social Responsibility",
      h3: "Social Responsibility",
      paragraph: [
        "We believe in giving back to the communities we serve. We are committed to social responsibility initiatives, working to make a positive impact on society and the environment.",
      ],
      img: HomeP5Social,
      imgRotate: HomeP5Info,
    },
    {
      id: "AwsSeven",
      name: "Collaboration",
      h3: "Collaboration",
      paragraph: [
        "Collaboration is the key to success. We work closely with our clients, partners, and team members, fostering a culture of cooperation and knowledge sharing to achieve collective goals.",
      ],
      img: HomeP5Collaboration,
      imgRotate: HomeP5Info,
    },
  ];

  const resetInterval = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    intervalRef.current = setInterval(() => {
      setActiveButton((prevActiveButton) => (prevActiveButton + 1) % HomePartFiveInfoContent.length);
    }, 5000);
  };

  const changeSlide = (index) => {
    setActiveButton(index);
    resetInterval();
  };

  useEffect(() => {
    resetInterval();

    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  return (
    <div className="HomePartFiveInfoContainer">
      <Buttons contents={HomePartFiveInfoContent} activeButton={activeButton} changeSlide={changeSlide} />
      <ContentDisplay content={HomePartFiveInfoContent[activeButton]} />
    </div>
  );
}

function Buttons({ contents, activeButton, changeSlide }) {
  return (
    <div className="HomePartFiveInfo_container">
      {contents.map((content, index) => (
        <div key={index} className={`HomePartFiveInfo_button ${activeButton === index ? "HomePartFiveInfo_active" : ""}`} onClick={() => changeSlide(index)}>
          {content.name}
        </div>
      ))}
    </div>
  );
}

function ContentDisplay({ content }) {
  const imageKey = `${content.id}-${new Date().getTime()}`;

  return (
    <div className="HomePartFiveInfo_content">
      <div className="HomePartFiveInfoImg_MoreInfo">
        {content.img && <LazyMedia type="image" src={content.img} alt={content.name} className="HomeP5_Img_MoreInfo" />}
      </div>
      <div className="HomePartFiveInfo_MoreInfo_container">
        <div className="Home_P5_Info_S1">
          <h3 className="Home_P5_Info_S1_H3">{content.h3}</h3>
          <LazyMedia type="image" key={imageKey} src={content.imgRotate} alt={content.h3} className="Home_P5_S1_Img_Rotate" />
        </div>
        {content.paragraph.map((text, index) => (
          <p key={index} className="HomePartFiveInfo_MoreInfo_container_P">
            {text}
          </p>
        ))}
      </div>
    </div>
  );
}

export default HomePartFiveInfo;
