import "./App.css";
import { BrowserRouter as Routes, Route, Switch } from "react-router-dom";
import ContactUs from "./pages/contact-us/index";
import Careers from "./pages/careers/index";
import DevOps from "./pages/devOps/index";
import Sap from "./pages/Sap_partnerShip/index";
import HomePage from "./pages/home/index";
import AwsPartnerShip from "./pages/Aws_partnerShip/index";
import AzurePartnerShip from "./pages/Azure_PartnerShip/Index";
import SuccessFactors from "./pages/Sap_SuccessFactors/index";
import OilsGas from "./pages/Oils_GAS/index";
import Government from "./pages/Government/index";
import Manufacturing from "./pages/Manufacturing/index";
import AboutUs from "./pages/About-us/index";
import SapAriba from "./pages/Sap_Ariba/index";
import SapRise from "./pages/Sap_Rise/index";
import S4HANA from "./pages/S4Hana/index";
import Odoo from "./pages/Odoo/index";
import Clients from "./pages/Clients/index";
import RealEstate from "./pages/Real_Estate/index";
import TrendMicro from "./pages/Trend_Micro/index";
import Tender from "./pages/tender/index";
import CaseStudy from "./pages/caseStudy/index";
import CaseStudyHsa from "./pages/caseStudyHSA/index";
import CaseStudyPharmaoverseas from "./pages/caseStudyPharmaoverseas/index";
import CaseStudyEGIC from "./pages/caseStudyEGIC/index";
import CaseStudyAMer from "./pages/caseStudyAmer/index";
import CaseStudyDamietta from "./pages/caseStudyDamietta";
import DigitalMarketing from "./pages/Digital_Marketing/index";
import CaseStudyArabDevelopers from "./pages/caseStudyArabDevelopers";
import ScrollToTop from "./components/ScrollUp/ScrollUp";
function App() {
  return (
    <>
      <div className="App">
        <Routes>
          <ScrollToTop />
          <Switch>
            {/* About */}
            <Route path="/About-Us">
              <AboutUs />
            </Route>
            {/* Services */}
            <Route path="/devops">
              <DevOps />
            </Route>
            <Route path="/DigitalMarketing">
              <DigitalMarketing />
            </Route>
            {/* Industries */}
            <Route path="/oils-gas">
              <OilsGas />
            </Route>
            <Route path="/Government">
              <Government />
            </Route>
            <Route path="/Manufacturing">
              <Manufacturing />
            </Route>
            {/* Solutions */}
            <Route path="/RealEstate">
              <RealEstate />
            </Route>
            <Route path="/Tender">
              <Tender />
            </Route>
            {/* Partners */}
            <Route path="/aws-partnership">
              <AwsPartnerShip />
            </Route>
            <Route path="/sap-partnership">
              <Sap />
            </Route>
            <Route path="/sap-SuccessFactors">
              <SuccessFactors />
            </Route>
            <Route path="/Sap-Ariba">
              <SapAriba />
            </Route>
            <Route path="/S/4hana">
              <S4HANA />
            </Route>
            <Route path="/Sap-Rise">
              <SapRise />
            </Route>
            <Route path="/Azure-partnership">
              <AzurePartnerShip />
            </Route>
            <Route path="/Trend-Micro">
              <TrendMicro />
            </Route>
            {/* <Route path="/Odoo">
              <Odoo />
            </Route> */}
            {/* more */}
            <Route path="/Clients">
              <Clients />
            </Route>
            <Route path="/careers">
              <Careers />
            </Route>
            <Route path="/case-studies">
              <CaseStudy />
            </Route>
            <Route path="/Hsa">
              <CaseStudyHsa />
            </Route>
            <Route path="/Pharmaoverseas">
              <CaseStudyPharmaoverseas />
            </Route>
            <Route path="/EGIC">
              <CaseStudyEGIC />
            </Route>
            <Route path="/AmerGroup">
              <CaseStudyAMer />
            </Route>
            <Route path="/Damietta">
              <CaseStudyDamietta />
            </Route>
            <Route path="/ADH">
              <CaseStudyArabDevelopers />
            </Route>
            {/* Conatct us */}
            <Route path="/contact-us">
              <ContactUs />
            </Route>
            {/* home Page */}
            <Route path="/">
              <HomePage />
            </Route>
          </Switch>
        </Routes>
      </div>
    </>
  );
}

export default App;
