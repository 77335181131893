import React from "react";
import "./HomePartEightSlider.css";
// Layer 1 Images
import HomeP8L1Img1 from "../../../assets/svg/Home_P8_L2_Ministry_foreign_Affairs.svg"; // done
import HomeP8L1Img2 from "../../../assets/svg/Home_P8_L2_Planning_and_Economic_deveopment.svg"; // done
import HomeP8L1Img3 from "../../../assets/images/Home_P8_L2_Ministry_communications.png"; // done
import HomeP8L1Img4 from "../../../assets/images/Home_P8_L1_Elgehaz_Elmarkzy.png"; // done
import HomeP8L1Img5 from "../../../assets/images/Home_P8_L1_Haras_Amiri.jpg"; // done
import HomeP8L1Img6 from "../../../assets/svg/Home_P8_L2_Etihad.svg"; // done
import HomeP8L1Img7 from "../../../assets/svg/Home_P8_L2_Haras.svg"; // done
import HomeP8L1Img8 from "../../../assets/images/Home_P8_L1_Hay2a_Arabya.png"; // done
import HomeP8L1Img9 from "../../../assets/images/Home_P8_L1_Agiba.png"; // done
import HomeP8L1Img10 from "../../../assets/images/Home_P8_L1_Egas.png"; // done
import HomeP8L1Img11 from "../../../assets/images/Home_P8_L1_Jocap.png"; // done
import HomeP8L1Img12 from "../../../assets/images/Home_P8_L1_PetroBel.png"; // done
import HomeP8L1Img13 from "../../../assets/images/Home_P8_L1_DLNG.png"; // done
import HomeP8L1Img14 from "../../../assets/images/Home_P8_L1_PetroJet.png"; // done
import HomeP8L1Img15 from "../../../assets/images/Home_P8_L1_Oscar.png"; // done
import HomeP8L1Img16 from "../../../assets/images/Home_P8_L2_Adlok.png"; // done
import HomeP8L1Img17 from "../../../assets/images/Home_P8_L1_IctQatar.png"; // done
import HomeP8L1Img18 from "../../../assets/svg/Home_P8_L1_Stc.svg"; // done
import HomeP8L1Img19 from "../../../assets/svg/Home_P8_L1_Zain.svg"; // done
import HomeP8L1Img20 from "../../../assets/images/Home_P8_L1_OmanTel.png"; // done
import HomeP8L1Img21 from "../../../assets/svg/Home_P8_L1_Elaraby.svg"; // done
import HomeP8L1Img22 from "../../../assets/images/Home_P8_L1_AmerGroup.png"; // done
import HomeP8L1Img23 from "../../../assets/images/Home_P8_L1_JUHAYNA.webp"; // done
import HomeP8L1Img24 from "../../../assets/images/Clients_P2_EGic.png"; // done
import HomeP8L1Img25 from "../../../assets/images/Home_P8_L1_Adh.png"; // done

// Layer 2Images
import HomeP8L1Img26 from "../../../assets/images/Home_P8_L2_Edita.png"; // done
import HomeP8L1Img27 from "../../../assets/images/Home_P8_L2_Zenia.png"; // done
import HomeP8L1Img28 from "../../../assets/images/Home_P8_L2_Elqana.png"; // done
import HomeP8L1Img29 from "../../../assets/images/Home_P8_L2_EcoBat.png"; // done
import HomeP8L1Img30 from "../../../assets/images/Home_P8_L2_Magrabi.png"; // done
import HomeP8L1Img31 from "../../../assets/images/Home_P8_L2_Mido.png"; // done
import HomeP8L1Img32 from "../../../assets/images/Home_P8_L2_PharmaOverseas.png"; // done
import HomeP8L1Img33 from "../../../assets/images/Home_P8_L2_Wadi_Degla.png"; // done
import HomeP8L1Img34 from "../../../assets/images/Home_P8_L2_HSA.png"; // done
import HomeP8L1Img35 from "../../../assets/images/Home_P8_L2_AboGhaly.png"; // done
import HomeP8L1Img36 from "../../../assets/images/Home_P8_L2_Alsalam.png"; // done
import HomeP8L1Img37 from "../../../assets/images/Home_P8_L2_Nuafar.png"; // done
import HomeP8L1Img38 from "../../../assets/images/Home_P8_L2_Alfa.png"; // done
import HomeP8L1Img39 from "../../../assets/images/Home_P8_L2_EgyptFood.jpg"; // done
import HomeP8L1Img40 from "../../../assets/images/Home_P8_L2_Ittihad.png"; // done
import HomeP8L1Img41 from "../../../assets/images/Home_P8_L2_Damietta.png"; // done
import HomeP8L1Img42 from "../../../assets/images/Home_P8_L2_Magrabi_Hospitals.png"; // done
import HomeP8L1Img43 from "../../../assets/images/Home_P8_L2_EstrenCompany.png"; // done
import HomeP8L1Img44 from "../../../assets/images/Home_P8_L2_Center.png"; // done
import HomeP8L1Img45 from "../../../assets/images/Home_P8_L2_Makan.png"; // done
import HomeP8L1Img46 from "../../../assets/images/Home_P8_L2_Metra.png"; // done
import HomeP8L1Img47 from "../../../assets/images/Home_P8_L2_Mobica.png"; // done
import HomeP8L1Img48 from "../../../assets/images/Home_P8_L2_SBMNEW.jpg"; // done
import HomeP8L1Img49 from "../../../assets/images/Home_P8_L2_Tax.png"; // done
import HomeP8L1Img50 from "../../../assets/images/RTA_Dubai_logo.png";

// Home_P8_L1_Zain.svg
function HomePartEightSlider() {
  return (
    <>
      <div className="Main_Container_P8_New">
        <div className="Home_P8_S2_Shadow_Right"></div>
        <div className="Home_P8_S2_Container">
          {/* put function here */}
          <TopLine />
          <BottomLine />
        </div>
        <div className="Home_P8_S2_Shadow_left"></div>
      </div>
    </>
  );
}

export default HomePartEightSlider;

function TopLine() {
  return (
    <>
      <div className="left-wrapper-new">
        <div className="Home_P8_S2_Layer1_Wrapper">
          <div className="Home_P8_S2_Layer1">
            {" "}
            {/* img one */}
            <img src={HomeP8L1Img1} alt="Brands" className="Slide_Home_P8_Img1 NoHover" />
            {/* img two */}
            <img src={HomeP8L1Img2} alt="Brands" className="Slide_Home_P8_Img2 NoHover" />
            {/* img three */}
            <img src={HomeP8L1Img3} alt="Brands" className="Slide_Home_P8_Img3 NoHover" />
            {/* img four */}
            <img src={HomeP8L1Img4} alt="Brands" className="Slide_Home_P8_Img4 NoHover" />
            {/* img five */}
            <img src={HomeP8L1Img5} alt="Brands" className="Slide_Home_P8_Img5 NoHover" />
            {/* img six */}
            <img src={HomeP8L1Img6} alt="Brands" className="Slide_Home_P8_Img6 NoHover" />
            {/* img seven */}
            <img src={HomeP8L1Img7} alt="Brands" className="Slide_Home_P8_Img7 NoHover" />
            {/* img eaight */}
            <img src={HomeP8L1Img8} alt="Brands" className="Slide_Home_P8_Img8 NoHover" />
            {/* img nine */}
            <img src={HomeP8L1Img9} alt="Brands" className="Slide_Home_P8_Img9 NoHover" />
            {/* img ten */}
            <img src={HomeP8L1Img10} alt="Brands" className="Slide_Home_P8_Img10 NoHover" />
            {/* img elven */}
            <img src={HomeP8L1Img11} alt="Brands" className="Slide_Home_P8_Img11 NoHover" />
            {/* img 12 */}
            <img src={HomeP8L1Img12} alt="Brands" className="Slide_Home_P8_Img12 NoHover" />
            {/* img 13  */}
            <img src={HomeP8L1Img13} alt="Brands" className="Slide_Home_P8_Img13 NoHover" />
            {/* img 14  */}
            <img src={HomeP8L1Img14} alt="Brands" className="Slide_Home_P8_Img14 NoHover" />
            {/* img 15  */}
            <img src={HomeP8L1Img15} alt="Brands" className="Slide_Home_P8_Img15 NoHover" />
            {/* img 16  */}
            <img src={HomeP8L1Img16} alt="Brands" className="Slide_Home_P8_Img16 NoHover" />
            {/* img 17  */}
            <img src={HomeP8L1Img17} alt="Brands" className="Slide_Home_P8_Img17 NoHover" />
            {/* img 18  */}
            <img src={HomeP8L1Img18} alt="Brands" className="Slide_Home_P8_Img18 NoHover" />
            {/* img 19  */}
            <img src={HomeP8L1Img19} alt="Brands" className="Slide_Home_P8_Img19 NoHover" />
            {/* img 20 */}
            <img src={HomeP8L1Img20} alt="Brands" className="Slide_Home_P8_Img20 NoHover" />
            {/* img 21 */}
            <img src={HomeP8L1Img21} alt="Brands" className="Slide_Home_P8_Img21 NoHover" />
            {/* img 22 */}
            <img src={HomeP8L1Img22} alt="Brands" className="Slide_Home_P8_Img22 NoHover" />
            {/* img 23 */}
            <img src={HomeP8L1Img23} alt="Brands" className="Slide_Home_P8_Img23 NoHover" />
            {/* img 24 */}
            <img src={HomeP8L1Img24} alt="Brands" className="Slide_Home_P8_Img24 NoHover" />
            {/* img 25 */}
            <img src={HomeP8L1Img25} alt="Brands" className="Slide_Home_P8_Img25 NoHover" />
          </div>
          {/*  */}
          {/* repeat line 1 */}
          <div className="Home_P8_S2_Layer1">
            {/* img one re */}
            <img src={HomeP8L1Img1} alt="Brands" className="Slide_Home_P8_Img1 NoHover" />
            {/* img two */}
            <img src={HomeP8L1Img2} alt="Brands" className="Slide_Home_P8_Img2 NoHover" />
            {/* img three */}
            <img src={HomeP8L1Img3} alt="Brands" className="Slide_Home_P8_Img3 NoHover" />
            {/* img four */}
            <img src={HomeP8L1Img4} alt="Brands" className="Slide_Home_P8_Img4 NoHover" />
            {/* img five */}
            <img src={HomeP8L1Img5} alt="Brands" className="Slide_Home_P8_Img5 NoHover" />
            {/* img six */}
            <img src={HomeP8L1Img6} alt="Brands" className="Slide_Home_P8_Img6 NoHover" />
            {/* img seven */}
            <img src={HomeP8L1Img7} alt="Brands" className="Slide_Home_P8_Img7 NoHover" />
            {/* img eaight */}
            <img src={HomeP8L1Img8} alt="Brands" className="Slide_Home_P8_Img8 NoHover" />
            {/* img nine */}
            <img src={HomeP8L1Img9} alt="Brands" className="Slide_Home_P8_Img9 NoHover" />
            {/* img ten */}
            <img src={HomeP8L1Img10} alt="Brands" className="Slide_Home_P8_Img10 NoHover" />
            {/* img elven */}
            <img src={HomeP8L1Img11} alt="Brands" className="Slide_Home_P8_Img11 NoHover" />
            {/* img 12 */}
            <img src={HomeP8L1Img12} alt="Brands" className="Slide_Home_P8_Img12 NoHover" />
            {/* img 13  */}
            <img src={HomeP8L1Img13} alt="Brands" className="Slide_Home_P8_Img13 NoHover" />
            {/* img 14  */}
            <img src={HomeP8L1Img14} alt="Brands" className="Slide_Home_P8_Img14 NoHover" />
            {/* img 15  */}
            <img src={HomeP8L1Img15} alt="Brands" className="Slide_Home_P8_Img15 NoHover" />
            {/* img 16  */}
            <img src={HomeP8L1Img16} alt="Brands" className="Slide_Home_P8_Img16 NoHover" />
            {/* img 17  */}
            <img src={HomeP8L1Img17} alt="Brands" className="Slide_Home_P8_Img17 NoHover" />
            {/* img 18  */}
            <img src={HomeP8L1Img18} alt="Brands" className="Slide_Home_P8_Img18 NoHover" />
            {/* img 19  */}
            <img src={HomeP8L1Img19} alt="Brands" className="Slide_Home_P8_Img19 NoHover" />
            {/* img 20 */}
            <img src={HomeP8L1Img20} alt="Brands" className="Slide_Home_P8_Img20 NoHover" />
            {/* img 21 */}
            <img src={HomeP8L1Img21} alt="Brands" className="Slide_Home_P8_Img21 NoHover" />
            {/* img 22 */}
            <img src={HomeP8L1Img22} alt="Brands" className="Slide_Home_P8_Img22 NoHover" />
            {/* img 23 */}
            <img src={HomeP8L1Img23} alt="Brands" className="Slide_Home_P8_Img23 NoHover" />
            {/* img 24 */}
            <img src={HomeP8L1Img24} alt="Brands" className="Slide_Home_P8_Img24 NoHover" />
            {/* img 25 */}
            <img src={HomeP8L1Img25} alt="Brands" className="Slide_Home_P8_Img25 NoHover" />
          </div>
        </div>
      </div>
    </>
  );
}
function BottomLine() {
  return (
    <>
      <div className="right-wrapper-new">
        <div className="Home_P8_S2_Layer2_Wrapper">
          <div className="Home_P8_S2_Layer2">
            {/* img one */}
            <img src={HomeP8L1Img26} alt="HomeP8LayerImg" className="Slide_Home_P8_Img26 NoHover" />{" "}
            <img src={HomeP8L1Img27} alt="HomeP8LayerImg" className="Slide_Home_P8_Img27 NoHover" />{" "}
            <img src={HomeP8L1Img28} alt="HomeP8LayerImg" className="Slide_Home_P8_Img28 NoHover" />{" "}
            <img src={HomeP8L1Img29} alt="HomeP8LayerImg" className="Slide_Home_P8_Img29 NoHover" />{" "}
            <img src={HomeP8L1Img30} alt="HomeP8LayerImg" className="Slide_Home_P8_Img30 NoHover" />{" "}
            <img src={HomeP8L1Img31} alt="HomeP8LayerImg" className="Slide_Home_P8_Img31 NoHover" />{" "}
            <img src={HomeP8L1Img32} alt="HomeP8LayerImg" className="Slide_Home_P8_Img32 NoHover" />{" "}
            <img src={HomeP8L1Img33} alt="HomeP8LayerImg" className="Slide_Home_P8_Img33 NoHover" />{" "}
            <img src={HomeP8L1Img34} alt="HomeP8LayerImg" className="Slide_Home_P8_Img34 NoHover" />{" "}
            <img src={HomeP8L1Img35} alt="HomeP8LayerImg" className="Slide_Home_P8_Img35 NoHover" />{" "}
            <img src={HomeP8L1Img36} alt="HomeP8LayerImg" className="Slide_Home_P8_Img36 NoHover" />{" "}
            <img src={HomeP8L1Img37} alt="HomeP8LayerImg" className="Slide_Home_P8_Img37 NoHover" />{" "}
            <img src={HomeP8L1Img38} alt="HomeP8LayerImg" className="Slide_Home_P8_Img38 NoHover" />{" "}
            <img src={HomeP8L1Img39} alt="HomeP8LayerImg" className="Slide_Home_P8_Img39 NoHover" />
            <img src={HomeP8L1Img40} alt="HomeP8LayerImg" className="Slide_Home_P8_Img40 NoHover" />
            <img src={HomeP8L1Img41} alt="HomeP8LayerImg" className="Slide_Home_P8_Img41 NoHover" />{" "}
            <img src={HomeP8L1Img42} alt="HomeP8LayerImg" className="Slide_Home_P8_Img42 NoHover" />{" "}
            <img src={HomeP8L1Img43} alt="HomeP8LayerImg" className="Slide_Home_P8_Img43 NoHover" />{" "}
            <img src={HomeP8L1Img44} alt="HomeP8LayerImg" className="Slide_Home_P8_Img44 NoHover" />{" "}
            <img src={HomeP8L1Img45} alt="HomeP8LayerImg" className="Slide_Home_P8_Img45 NoHover" />{" "}
            <img src={HomeP8L1Img46} alt="HomeP8LayerImg" className="Slide_Home_P8_Img46 NoHover" />{" "}
            <img src={HomeP8L1Img47} alt="HomeP8LayerImg" className="Slide_Home_P8_Img47 NoHover" />
            <img src={HomeP8L1Img48} alt="HomeP8LayerImg" className="Slide_Home_P8_Img48 NoHover" />{" "}
            <img src={HomeP8L1Img49} alt="HomeP8LayerImg" className="Slide_Home_P8_Img49 NoHover" />
            <img src={HomeP8L1Img50} alt="HomeP8LayerImg" className="Slide_Home_P8_Img50 NoHover" />
          </div>
          <div className="Home_P8_S2_Layer2">
            {/* img one */}
            <img src={HomeP8L1Img26} alt="HomeP8LayerImg" className="Slide_Home_P8_Img26 NoHover" />{" "}
            <img src={HomeP8L1Img27} alt="HomeP8LayerImg" className="Slide_Home_P8_Img27 NoHover" />{" "}
            <img src={HomeP8L1Img28} alt="HomeP8LayerImg" className="Slide_Home_P8_Img28 NoHover" />{" "}
            <img src={HomeP8L1Img29} alt="HomeP8LayerImg" className="Slide_Home_P8_Img29 NoHover" />{" "}
            <img src={HomeP8L1Img30} alt="HomeP8LayerImg" className="Slide_Home_P8_Img30 NoHover" />{" "}
            <img src={HomeP8L1Img31} alt="HomeP8LayerImg" className="Slide_Home_P8_Img31 NoHover" />{" "}
            <img src={HomeP8L1Img32} alt="HomeP8LayerImg" className="Slide_Home_P8_Img32 NoHover" />{" "}
            <img src={HomeP8L1Img33} alt="HomeP8LayerImg" className="Slide_Home_P8_Img33 NoHover" />{" "}
            <img src={HomeP8L1Img34} alt="HomeP8LayerImg" className="Slide_Home_P8_Img34 NoHover" />{" "}
            <img src={HomeP8L1Img35} alt="HomeP8LayerImg" className="Slide_Home_P8_Img35 NoHover" />{" "}
            <img src={HomeP8L1Img36} alt="HomeP8LayerImg" className="Slide_Home_P8_Img36 NoHover" />{" "}
            <img src={HomeP8L1Img37} alt="HomeP8LayerImg" className="Slide_Home_P8_Img37 NoHover" />{" "}
            <img src={HomeP8L1Img38} alt="HomeP8LayerImg" className="Slide_Home_P8_Img38 NoHover" />{" "}
            <img src={HomeP8L1Img39} alt="HomeP8LayerImg" className="Slide_Home_P8_Img39 NoHover" />
            <img src={HomeP8L1Img40} alt="HomeP8LayerImg" className="Slide_Home_P8_Img40 NoHover" />
            <img src={HomeP8L1Img41} alt="HomeP8LayerImg" className="Slide_Home_P8_Img41 NoHover" />{" "}
            <img src={HomeP8L1Img42} alt="HomeP8LayerImg" className="Slide_Home_P8_Img42 NoHover" />{" "}
            <img src={HomeP8L1Img43} alt="HomeP8LayerImg" className="Slide_Home_P8_Img43 NoHover" />{" "}
            <img src={HomeP8L1Img44} alt="HomeP8LayerImg" className="Slide_Home_P8_Img44 NoHover" />{" "}
            <img src={HomeP8L1Img45} alt="HomeP8LayerImg" className="Slide_Home_P8_Img45 NoHover" />{" "}
            <img src={HomeP8L1Img46} alt="HomeP8LayerImg" className="Slide_Home_P8_Img46 NoHover" />{" "}
            <img src={HomeP8L1Img47} alt="HomeP8LayerImg" className="Slide_Home_P8_Img47 NoHover" />
            <img src={HomeP8L1Img48} alt="HomeP8LayerImg" className="Slide_Home_P8_Img48 NoHover" />{" "}
            <img src={HomeP8L1Img49} alt="HomeP8LayerImg" className="Slide_Home_P8_Img49 NoHover" />
            <img src={HomeP8L1Img50} alt="HomeP8LayerImg" className="Slide_Home_P8_Img50 NoHover" />
          </div>
        </div>
      </div>
    </>
  );
}
