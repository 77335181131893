import React, { useState, useRef, useEffect } from "react";
import "./headerMobile.css";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/svg/Logo-White.svg";
import { ReactComponent as OpenIcon } from "../../assets/svg/OpenIconMobile.svg";
import { ReactComponent as CLoseIcon } from "../../assets/svg/CLoseIconMobile.svg";
import { ReactComponent as OpenArrow } from "../../assets/svg/MobileOpenDownArrow.svg";
import ServicesSubmenuMobile from "../SubmenuMobile/ServicesMobile/servicesSubmenuMobile";
import IndustriesSubmenuMobile from "../SubmenuMobile/IndustriesMobile/industriesSubmenuMobile";
import SolutionsSubmenuMobile from "../SubmenuMobile/SolutionsMobile/solutionsSubmenuMobile";
import PartnersSubmenuMobile from "../SubmenuMobile/PartnersMobile/partnersSubmenuMobile";
import MoreSubmenuMobile from "../SubmenuMobile/MoreMobile/MoreSubMenuMobile";
import useOutsideClick from "./useOutSideCLick";

function HeaderMobile() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [submenuType, setSubmenuType] = useState(null);
  const submenuRef = useRef();

  useOutsideClick(submenuRef, () => {
    if (isMenuOpen) {
      setIsMenuOpen(false);
      setSubmenuType(null);
    }
  });

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleSubmenu = (type) => {
    setSubmenuType(submenuType === type ? null : type);
  };

  useEffect(() => {}, []);
  return (
    <>
      <div className="HeaderMobile_Container">
        <Link to="/">
          <Logo className="LogoMobile" />
        </Link>
        <button className="Button_Open_Icon" onClick={toggleMenu}>
          {isMenuOpen ? <CLoseIcon /> : <OpenIcon />}
        </button>
      </div>

      {isMenuOpen && (
        <div className="SubmenusMobile_Container" ref={submenuRef}>
          {/* home page */}
          <div className="headerMobile_nav_a">
            <Link to="/" className="headerMobile_nav_Container">
              <p className="headerMobile_nav_p">Home</p>
            </Link>
          </div>
          {/* About page */}
          <div className="headerMobile_nav_a">
            <Link to="/About-us" className="headerMobile_nav_Container">
              <p className="headerMobile_nav_p">About</p>
            </Link>
          </div>
          {/* Services SubMenu */}
          <div className="headerMobile_nav_a">
            <a onClick={() => toggleSubmenu("services")} data-type="services" className="headerMobile_nav_Container">
              <p className="headerMobile_nav_p">Services</p>
              <OpenArrow className={`headerMobile_nav_icon ${submenuType === "services" ? "rotate-arrow" : ""}`} />
            </a>
            {submenuType === "services" && <ServicesSubmenuMobile />}
          </div>
          {/* Industries SubMenu */}
          <div className="headerMobile_nav_a">
            <a onClick={() => toggleSubmenu("Industries")} data-type="Industries" className="headerMobile_nav_Container">
              <p className="headerMobile_nav_p">Industries</p>
              <OpenArrow className={`headerMobile_nav_icon ${submenuType === "Industries" ? "rotate-arrow" : ""}`} />
            </a>
            {submenuType === "Industries" && <IndustriesSubmenuMobile />}
          </div>
          {/* Solutions SubMenu */}
          <div className="headerMobile_nav_a">
            <a onClick={() => toggleSubmenu("Solutions")} data-type="Solutions" className="headerMobile_nav_Container">
              <p className="headerMobile_nav_p">Products</p>
              <OpenArrow className={`headerMobile_nav_icon ${submenuType === "Solutions" ? "rotate-arrow" : ""}`} />
            </a>
            {submenuType === "Solutions" && <SolutionsSubmenuMobile />}
          </div>
          {/* Partners SubMenu */}
          <div className="headerMobile_nav_a">
            <a onClick={() => toggleSubmenu("Partners")} data-type="Partners" className="headerMobile_nav_Container">
              <p className="headerMobile_nav_p">Partners</p>
              <OpenArrow className={`headerMobile_nav_icon ${submenuType === "Partners" ? "rotate-arrow" : ""}`} />
            </a>
            {submenuType === "Partners" && <PartnersSubmenuMobile />}
          </div>
          <div className="headerMobile_nav_a">
            <a onClick={() => toggleSubmenu("More")} data-type="More" className="headerMobile_nav_Container">
              <p className="headerMobile_nav_p">More</p>
              <OpenArrow className={`headerMobile_nav_icon ${submenuType === "More" ? "rotate-arrow" : ""}`} />
            </a>
            {submenuType === "More" && <MoreSubmenuMobile />}
          </div>

          {/* About-Us Page */}
          <div className="headerMobile_nav_a">
            <Link to="/Contact-us" className="headerMobile_nav_Container">
              <p className="headerMobile_nav_p">Contact Us</p>
            </Link>
          </div>
        </div>
      )}
    </>
  );
}

export default HeaderMobile;
