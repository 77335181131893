import React from "react";
import "./footer-contactus.css";
import { ReactComponent as Inbox } from "../../assets/svg/inbox.svg";
import { ReactComponent as DotMenu } from "../../assets/svg/dot-menu.svg";
import { ReactComponent as BackgroundSvg } from "../../assets/svg/bg-footer.svg";
import { ReactComponent as GoOut } from "../../assets/svg/go-out.svg";
import { ReactComponent as Facebook } from "../../assets/svg/facebook.svg";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import { Link } from "react-router-dom";
import YouTubeIcon from "@mui/icons-material/YouTube";
function Footer() {
  return (
    <>
      <div className="footer">
        {/* footer part 1 */}
        {/* <div className="footer_part1">
          <div className="footer_part1_first">
            <Inbox className="part1_first_i" />
            <p className="part1_first_p">
              get the latest Phoenix news in your inbox
            </p>
          </div>
          <div className="footer_part1_second">
            <input
              type="text"
              className="part1_second_input"
              placeholder="youremail@example.com"
            />
            <a href={`${MainUrl}Contact-us`}>
              <button className="part1_second_button">Subscribe</button>
            </a>
          </div>
        </div> */}
        {/* end footer part 1 */}
        {/* start footer part 2  */}
        <div className="footer_part2">
          <BackgroundSvg className="background-svg" />
          <div className="part1">
            <div className="part2_first_link">
              <DotMenu className="footer_icon" />
              <span className="Main-link">Company</span>
            </div>
            <Link to="/about-us">About Us</Link>
            <Link to="/Careers">Careers</Link>
          </div>
          {/*  */}
          {/*  */}
          <div className="part2">
            <div className="part2_first_link">
              <DotMenu className="footer_icon" />
              <span className="Main-link">Help</span>
            </div>
            <Link to="/Contact-us">Contact Us</Link>
            <Link to="/Clients">Clients</Link>
          </div>
          {/*  */}
          {/*  */}
          <div className="part3_contact_us">
            <div className="part2_first_link">
              <DotMenu className="footer_icon" />
              <span className="Main-link">Branches location</span>
            </div>
            <h4 className="footer_part3_h4_Web">Phoenix Consulting</h4>
            <p className="footer_part3_P_Web">
              <span>Egypt:</span> Al Maadi,Cairo Governorate.
            </p>
            {/*  */}
            <h4 className="footer_part3_h4_Web">Phoenix IT Solutions</h4>
            <p className="footer_part3_P_Web">
              <span>UAE:</span> International City, Dubai.
            </p>
            {/*  */}
            <h4 className="footer_part3_h4_Web">Phoenix Consulting</h4>
            <p className="footer_part3_P_Web">
              <span>KSA:</span> 6526 AL Takhasasi,3565 North Mathar Dist. RIYADH.
            </p>
            {/*  */}
            <h4 className="footer_part3_h4_Web">Phoenix Tech Solutions</h4>
            <p className="footer_part3_P_Web">
              <span>USA:</span> 2248 Broadway, New York.
            </p>
          </div>
          {/*  */}
          {/*  */}
          <div className="part4">
            <div className="part2_first_link">
              <DotMenu className="footer_icon" />
              <span className="Main-link">Contacts</span>
            </div>
            <p className="Footer_Part2_Address_Egypt">Egypt Office: (+20)1016165700</p>
            <p className="Footer_Part2_Address_Dubai">Dubai Office: (+971)527673263</p>
            <p className="Footer_Part2_Address_Dubai">
              KSA Office: (+966)505437193 <br /> <div className="KsaSN">(+966)558317605</div>
            </p>
            <p className="Footer_Part2_Address_Dubai">USA Office: (+1)201-449-4229</p>
            <a href="mailto:info@phoenix-mea.com">info@phoenix-mea.com</a>
          </div>
        </div>
        {/* end footer part 2 */}
        {/* start footer part 3 */}
        <div className="footer_part3">
          <div className="footer_part3_first">@All Rights reserved by phoenix Consulting</div>
          <div className="footer_part3_second">
            <a href="https://www.linkedin.com/company/phoenix-consulting-egy/" target="blank">
              <div className="footer_social">
                <LinkedInIcon />
                Linked In
                <GoOut />
              </div>
            </a>
            <a href="https://www.facebook.com/PhoenixConsultingEgypt?mibextid=LQQJ4d" target="blank">
              <div className="footer_social">
                <Facebook />
                Facebook
                <GoOut />
              </div>
            </a>
            <a href="https://www.instagram.com/phoenixconsultingmea/?igshid=MzRlODBiNWFlZA%3D%3D" target="blank">
              <div className="footer_social">
                <InstagramIcon />
                Instagram
                <GoOut />
              </div>
            </a>
            <a href="https://www.youtube.com/@phoenixconsultingmea" target="blank">
              <div className="footer_social">
                <YouTubeIcon className="social_icon" />
                Youtube
                <GoOut className="social_icon" />
              </div>
            </a>
          </div>
        </div>
        {/* end footer part 3 */}
      </div>
    </>
  );
}

export default Footer;
