import React from "react";
import "./industriesSubmenuMobile.css";
import { ReactComponent as OilGas } from "../../../assets/svg/oil&gas.svg";
import { ReactComponent as Manufacturing } from "../../../assets/svg/Manufacturing.svg";
import { ReactComponent as Government } from "../../../assets/svg/Government.svg";
import { Link } from "react-router-dom";

function IndustriesSubmenuMobile() {
  return (
    <div className="submenuMobile_industries_main">
      <Link to="/Oils-gas" className="submenuMobile_industries_link">
        <div className="submenuMobile_industries_linkWithLogo">
          <OilGas className="OilMobile" />
          Oil & Gas
        </div>
      </Link>

      <Link to="/Manufacturing" className="submenuMobile_industries_link">
        <div className="submenuMobile_industries_linkWithLogo">
          <Manufacturing className="ManufacturingMobile" />
          Manufacturing
        </div>
      </Link>

      <Link to="/Government" className="submenuMobile_industries_link">
        <div className="submenuMobile_industries_linkWithLogo">
          <Government className="GovernmentMobile" />
          Government
        </div>
      </Link>
    </div>
  );
}

export default IndustriesSubmenuMobile;
