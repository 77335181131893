import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import "./contact.css";
import PhoneNumberInput from "../../components/hooks/choose-country/country";
import { ReactComponent as Phone } from "../../assets/svg/phone.svg";
import { ReactComponent as MapPin } from "../../assets/svg/map-pin.svg";
import { ReactComponent as DirectionMap } from "../../assets/svg/direction.svg";
import { ReactComponent as Mail } from "../../assets/svg/mail.svg";
import { ReactComponent as SpaceBetween } from "../../assets/svg/Arrow-between-element.svg";
import { ReactComponent as SpaceSmall } from "../../assets/svg/Arrow-space-small.svg";
import { ReactComponent as Facebook } from "../../assets/svg/facebook-con.svg";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import Choices from "../../components/hooks/selectChoices/choice";
import ButtonWithInput from "../../components/hooks/button-with-input/button";
import { ReactComponent as SubmitMail } from "../../assets/svg/submit-mail.svg";

function ContactContent() {
  const form = useRef();
  const [selectedChoices, setSelectedChoices] = useState([]);
  const [inquiryText, setInquiryText] = useState("");

  const handleChoicesChange = (choices) => {
    setSelectedChoices(choices);
  };

  const handleInquiryTextChange = (text) => {
    setInquiryText(text);
  };

  const onPhoneNumberChange = (phoneNumber) => {
    console.log(phoneNumber);
  };

  const sendEmailAfterVerification = (e) => {
    e.preventDefault();

    const formData = new FormData(form.current);
    const choicesString = selectedChoices.map((choice) => `ID: ${choice.id}, Label: ${choice.label}`).join("; ");
    formData.append("user_choices", choicesString);
    formData.append("user_inquiry", inquiryText);

    emailjs.sendForm("service_1rso7xp", "template_vv4s1bj", form.current, "xJ0tBdfHVBR9i65ia").then(
      (result) => {
        alert("Thank you! Your email has been sent.");
      },
      (error) => {
        console.log(error.text);
        alert("Failed to send email. Please try again later.");
      }
    );
    if (e && e.target) {
      e.target.reset();
    }
  };

  return (
    <>
      <div className="container">
        {/* start contact-us page part 1 it inculde some text */}
        <div className="contact_part1">
          <div className="contact_part1_contain">
            <div className="contact_part1_first">
              <p>Contact Us</p>
            </div>
            <div className="contact_part1_second">
              <p>Discover how Phoenix can transform your business.</p>
            </div>
            <div className="contact_part1_third">
              <p>
                Contact us today to discuss your needs and explore innovative solutions. Enter your details, and one of our consultants will get back to you
                ASAP.
              </p>
            </div>
          </div>
        </div>
        {/* end contact-us page part 1 */}
        {/* start contact-us page part 2 it inculde  */}
        <div className="contact_part2">
          <div className="contact_part2_contain">
            <div className="contact_big_head">
              <p>Enter your details</p>
            </div>
            <form ref={form} onSubmit={sendEmailAfterVerification}>
              <div className="contact_part2_second">
                {/* here will be inputs for contact-us */}
                <div className="con_part2_Input1">
                  {/* first input for name */}
                  <label class="input">
                    <input class="input__field input_1" type="text" placeholder="Name" name="user_name" required />
                    <span class="input__label input_1">Your Name</span>
                  </label>
                  {/* second input for Email */}
                  <label class="input">
                    <input class="input__field input_1" type="text" placeholder="example@email.com" name="user_email" required />
                    <span class="input__label">Email</span>
                  </label>
                </div>
                {/* third input with choose country for phone number */}
                <div className="con_part2_Input2">
                  <PhoneNumberInput onPhoneNumberChange={onPhoneNumberChange} />
                </div>
                {/* next 2 input for company and Co founder */}
                <div className="con_part2_Input1">
                  {/* first input for name */}
                  <label class="input">
                    <input class="input__field input_1 input_1" type="text" placeholder="Company" name="user_company" required />
                    <span class="input__label input_1">Company</span>
                  </label>
                  {/* second input for Email */}
                  <label class="input">
                    <input class="input__field input_1" type="text" placeholder="CO founder" name="user_job" required />
                    <span class="input__label">Position</span>
                  </label>
                </div>
              </div>
              {/* */}
              <div className="contact_part2_third">
                <div className="contact_part2_third_s1">
                  <div className="solutionQuestion">What solution do you want to inquire about?</div>
                  <div className="solutionChoices">
                    <Choices onChoicesChange={handleChoicesChange} />
                  </div>
                  <div className="solutionInput">
                    <ButtonWithInput />
                  </div>
                  <div className="solutionSubmit">
                    <button className="Contact_submitButton" type="submit">
                      <SubmitMail />
                      Submit
                    </button>
                  </div>
                </div>
                <div className="contact_part2_third_s2">
                  <p>One of our consultants will get back to you ASAP.</p>
                </div>
              </div>
            </form>
          </div>
        </div>
        {/* end contact-us page part 2 */}
        {/* start contact-us page part 3 it inculde  */}
        <div className="contact_part3">
          <div className="contact_part3_contain">
            {/* part 3 first */}
            <div className="contact_big_head">
              <p>WHERE YOU CAN FIND US</p>
            </div>
            {/* part 3 second */}
            <div className="contact_part3_second">
              {/* part 3 info 1 */}
              <div className="con_part3_second_info">
                <Phone className="con_info_icon" />
                <p className="part3_second_info1_p_bold">Phone Number</p>
                <div className="contact_info1">
                  <p className="part3_second_info1_p">
                    <span>Egypt: </span> +201016165700
                  </p>
                  <p className="part3_second_info1_p">
                    <span>Dubai: </span> +971 527673263
                  </p>
                  <p className="part3_second_info1_p">
                    <span>USA : </span> (+1)201-449-4229
                  </p>
                </div>
              </div>
              {/* end info 1 */}
              <SpaceBetween className="SpaceBetweenMobile" />
              {/* part 3 info 2 */}
              <div className="con_part3_second_info">
                <MapPin className="con_info_icon" />
                <p className="part3_second_info1_p_bold">Branches location</p>
                <div className="contact_info1">
                  <h4 className="Contact_Info1_h4">Phoenix Consulting</h4>
                  {/* 
                  <p className="part3_second_info1_p">
                    <span>Egypt: </span> Al Maadi,Cairo Governorate.
                  </p> */}
                  <div className="part3_second_info_liveMap">
                    <a
                      href="https://www.google.com/maps/place/Phoenix+SAP+Consulting/@29.9603694,31.2495355,17z/data=!4m6!3m5!1s0x145847ee15e6b79d:0xd3c1223201ee2270!8m2!3d29.9603834!4d31.2495891!16s%2Fg%2F11hbsy7tss?hl=en-eg&entry=ttu"
                      target="blank"
                    >
                      <DirectionMap />
                      View on google maps
                    </a>
                  </div>
                </div>
                <div className="contact_info1">
                  <h4 className="Contact_Info1_h4">Phoenix IT Solutions</h4>
                  {/* <p className="part3_second_info1_p">
                    <span>Dubai: </span>International City, Dubai.
                  </p> */}
                  <div className="part3_second_info_liveMap">
                    <a href="https://maps.app.goo.gl/RWYjJ2MxBMVvtw489" target="blank">
                      <DirectionMap />
                      View on google maps
                    </a>
                  </div>
                </div>
                <div className="contact_info1">
                  <h4 className="Contact_Info1_h4">Phoenix Tech Solutions</h4>
                  {/* <p className="part3_second_info1_p">
                    <span>USA: </span>2248 Broadway, New York.
                  </p> */}
                  <div className="part3_second_info_liveMap">
                    <a href="https://maps.app.goo.gl/Qu7fNoscFZowantH6" target="blank">
                      <DirectionMap />
                      View on google maps
                    </a>
                  </div>
                </div>
              </div>
              {/* end info 2 */}
              <SpaceBetween className="SpaceBetweenMobile" />
              {/* part 3 info 3 */}
              <div className="con_part3_second_info">
                <Mail className="con_info_icon" />
                <p className="part3_second_info1_p_bold">Our Email</p>
                <a href="mailto:info@phoenix-mea.com" className="info_Mail">
                  info@phoenix-mea.com
                </a>
              </div>
            </div>
            {/* end info 3 */}
            {/* part 3 third */}
            <div className="contact_big_head">
              <p>Keep connected</p>
            </div>
            <div className="contact_part3_third">
              <div className="contact_part3_third_icon">
                <a href="https://www.linkedin.com/company/phoenix-consulting-egy/" target="blank">
                  <div className="contact_part3_social">
                    <LinkedInIcon />
                    Linked In
                  </div>
                </a>
                <SpaceSmall />
                <a href="https://www.facebook.com/phoenixconsultingmea" target="blank">
                  <div className="contact_part3_social">
                    <Facebook className="con_icon" />
                    Facebook
                  </div>
                </a>
                <SpaceSmall />
                <a href="https://www.instagram.com/phoenixconsultingmea/?igshid=MzRlODBiNWFlZA%3D%3D" target="blank">
                  <div className="contact_part3_social">
                    <InstagramIcon />
                    Instagram
                  </div>
                </a>
                <SpaceSmall />
                <a href="https://www.youtube.com/@phoenixconsultingmea" target="blank">
                  <div className="contact_part3_social">
                    <YouTubeIcon />
                    Youtube
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* end contact-us page part 3 */}
      </div>
    </>
  );
}

export default ContactContent;
