import React from "react";
import "./home.css";
import { Link } from "react-router-dom";
import HomeSlideOne from "../../components/hooks/HomePartOneSlider/HomePartOneSlider";
import HomePartThreeSliders from "../../components/hooks/HomePartThreeSliders/HomePartThreeSliders";
import HomeP4AccordionItem from "../../components/hooks/HomePartFourAcc/HomePartFourAcc";
import HomePartFiveInfo from "../../components/hooks/HomePartFiveInfo/HomePartFiveInfo";
import HomePartEightSlider from "../../components/hooks/HomePartEightSlider/HomePartEightSlider";
// import TestimonialHome from "../../components/hooks/TestimonialHome/TestimonialHome";
import HomeP2Svg from "../../assets/images/Home_P2.png";
import HomeP2Dot from "../../assets/svg/Home_P2_Dot.svg";
import HomeS4P1Img1 from "../../assets/images/Home/SapLogo.png";
import HomeS4P1Img2 from "../../assets/images/Home/AWSLogo.png";
import HomeS4P1Img3 from "../../assets/images/Home/MicrosoftAzureLogo.png";
import HomeS4P1Img4 from "../../assets/images/Home/TrindMicroLogo.png";
import HomeS4P1Img5 from "../../assets/images/Home/HuawieLogo.png";
import HomeS4P1Img6 from "../../assets/images/Home/BoomiLogo.png";
// import HomeP4Odoo from "../../assets/images/Odoo_P1_Logo.png";
// import { ReactComponent as HomeP4Analyst } from "../../assets/svg/Home_P4_Analyst.svg";
import { ReactComponent as SpaceBetweenSec } from "../../assets/svg/careers-space.svg";
import HomeP7Img from "../../assets/images/Home_P7_Img.png";
import HomeP6S2Arrow from "../../assets/svg/Home_P6_S2_Arrow.svg";
// import videohome from "../../assets/videos/HomeP1Slider1.mp4";
import LazyMedia from "../../lazyLoad/lazyLoad";

// end of import Svg for Home Page

function HomeContent() {
  const HomeP4AccordionData = [
    {
      id: "AccTwo",
      title: "SAP",
      title2: "View partnership",
      content: (
        <>
          Teaming up with SAP as a Gold Partner, we leverage our expertise to implement transformative solutions that optimize operations and create new
          opportunities. <br />
          This collaboration positions us as leaders in delivering enterprise-grade solutions, empowering businesses to streamline processes and achieve
          scalable growth in today’s digital landscape.
        </>
      ),
      img: <LazyMedia type="image" src={HomeS4P1Img1} alt="AWS Partner" className="HomeP4Aws" />,
      urlLink: "sap-partnership",
    },
    {
      id: "AccOne",
      title: "AWS",
      title2: "View partnership",
      content: (
        <>
          Being an AWS Advanced Partner, we deliver innovative cloud solutions that drive digital transformation. <br />
          Our expertise helps businesses unlock the full potential of AWS technologies, ensuring scalable and secure solutions that promote growth in a rapidly
          evolving digital landscape.
        </>
      ),
      img: <LazyMedia type="image" src={HomeS4P1Img2} alt="AWS Partner" className="HomeP4AwsW" />,

      urlLink: "aws-partnership",
    },
    {
      id: "AccThree",
      title: "Microsoft Azure",
      title2: "View partnership",
      content: (
        <>
          Our partnership with Microsoft Azure provides advanced cloud solutions that enhance efficiency, innovation, and scalability. <br />
          By combining Azure’s capabilities with our expertise, we help businesses optimize operations and unlock growth opportunities in a competitive
          marketplace.
        </>
      ),
      img: <LazyMedia type="image" src={HomeS4P1Img3} alt="AWS Partner" className="HomeP4AwsW" />,
      urlLink: "Azure-partnership",
    },
    {
      id: "AccFour",
      title: "Trend Micro",
      title2: "View partnership",
      content: (
        <>
          With Trend Micro, we deliver advanced cybersecurity solutions that effectively safeguard businesses from evolving threats. <br />
          Together, Combining Trend Micro’s powerful security technology with our experience, we help organizations proactively secure their data, mitigate
          risks, and ensure long-term stability in the evolving technological environment.
        </>
      ),
      img: <LazyMedia type="image" src={HomeS4P1Img4} alt="AWS Partner" className="HomeP4Aws" />,
      urlLink: "Trend-Micro",
    },
    {
      id: "AccFive",
      title: "Huawei",
      title2: "View partnership",
      content: (
        <>
          By partnering with Huawei, we combine world-class technology with local hosting in Egypt to deliver tailored solutions that drive growth and enhance
          efficiency. <br />
          This strategic collaboration empowers us to provide businesses with secure, high-performance cloud services, offering unparalleled reliability and
          proximity to support their success in the digital age.
        </>
      ),
      img: <LazyMedia type="image" src={HomeS4P1Img5} alt="AWS Partner" className="HomeP4AwsW" />,
      urlLink: "/",
    },
    {
      id: "AccSix",
      title: "Boomi",
      title2: "View partnership",
      content: (
        <>
          In collaboration with Boomi allows us to deliver powerful integration and automation solutions that effectively simplify connectivity and boost
          operational performance. <br />
          Together, we enable businesses to unlock their full potential and achieve remarkable results.
        </>
      ),
      img: <LazyMedia type="image" src={HomeS4P1Img6} alt="AWS Partner" className="HomeP4Aws" />,
      urlLink: "/",
    },
  ];

  return (
    <>
      <div className="HomeContainer">
        {/* Home Part One */}
        <div className="HomePartOne">
          <HomeSlideOne />
        </div>

        {/* end Home Part One */}
        {/* Home Part Two */}
        <div className="HomePartTwo">
          <div className="Home_P2_Main">
            {/* Home Part Two Section One */}
            <div className="Home_P2_First">
              <h1 className="Home_P2_First_H1">What defines Phoenix, as Phoenix?</h1>
              <p className="Home_P2_First_P">
                Regardless of your industry, location, or destination. We stand by your side, no matter the circumstances, to address your unique challenges
                with tailored solutions. We're driven by a relentless commitment to help you navigate the complexities of today's business landscape, ensuring
                success, step by step.
              </p>
              <div className="Home_P2_S1">
                <Link to="/About-us">
                  <button className="Home_P2_S1_button">read more about us</button>
                </Link>
                <LazyMedia type="image" src={HomeP2Dot} alt="HomeP2Dot" className="HomeP2Dot" />
              </div>
            </div>
            {/* Home Part Two Section Two */}
            <div className="Home_P2_Second">
              <LazyMedia type="image" src={HomeP2Svg} alt="HomeP2Svg" className="HomeP2Svg" />
            </div>
          </div>
        </div>

        {/* end Home Part Two */}
        {/* Home Part Three */}
        <div className="HomePartThree">
          <HomePartThreeSliders />
        </div>
        {/* end Home Part Three */}
        {/* Home Part Four */}
        <div className="HomePartFour">
          <div className="HomeP4_S1">
            <h1 className="HomeP4_S1_H1">our strategic partners for success</h1>
            <p className="HomeP4_S1_P">
              We place immense emphasis on forging strategic alliances with world-renowned technology leaders. These partnerships form the cornerstone of our
              philosophy, enabling us to harness the full potential of digital transformation initiatives undertaken by our clients. Our partner ecosystem is
              expansive, and we remain committed to exploring novel avenues for collaboration.
            </p>
          </div>
          <div className="HomeP4_S2_Container">
            {HomeP4AccordionData.map((data, index) => (
              <HomeP4AccordionItem key={index} title={data.title} title2={data.title2} content={data.content} img={data.img} urlLink={data.urlLink} />
            ))}
          </div>
        </div>
        {/* end Home Part Four */}
        {/* Home Space */}
        <div className="HomeSpace">
          <SpaceBetweenSec />
        </div>
        {/* Home Part Five */}
        <div className="HomePartFive">
          <div className="Home_P5_FirstSection">
            <h1 className="Home_P5_H1">Our Core Values</h1>
          </div>
          <HomePartFiveInfo />
        </div>
        {/* Home Space */}
        <div className="HomeSpace">
          <SpaceBetweenSec />
        </div>
        {/* Home Part Six */}
        <div className="HomePartSix">
          <div className="Home_P6_S1">
            <h1 className="Home_P6_S1_H1">Let numbers speak for us</h1>
          </div>
          <div className="Home_P6_S2">
            {/* element one */}
            <div className="Home_P6_S2_Element">
              <div className="HomeP6S2T">+65</div>
              <h3 className="HomeP6S2H3">Projects</h3>
            </div>
            <div className="Home_P6_S2_Arrow">
              <LazyMedia type="image" src={HomeP6S2Arrow} alt="ArrowSpace" />
            </div>
            {/* element two */}
            <div className="Home_P6_S2_Element">
              <div className="HomeP6S2T">+12</div>
              <h3 className="HomeP6S2H3">years of experience</h3>
            </div>
            <div className="Home_P6_S2_Arrow">
              <LazyMedia type="image" src={HomeP6S2Arrow} alt="ArrowSpace" className="Home_P6_S2_ArrowHide" />
            </div>
            {/* element three */}
            <div className="Home_P6_S2_Element">
              <div className="HomeP6S2T">+30</div>
              <h3 className="HomeP6S2H3">Support contract</h3>
            </div>
            <div className="Home_P6_S2_Arrow">
              <LazyMedia type="image" src={HomeP6S2Arrow} alt="ArrowSpace" className="HomeP6S2ArrowShow" />
            </div>
            {/* element four */}
            <div className="Home_P6_S2_Element">
              <div className="HomeP6S2T">+80</div>
              <h3 className="HomeP6S2H3">Customer base</h3>
            </div>
            <div className="Home_P6_S2_Arrow">
              <LazyMedia type="image" src={HomeP6S2Arrow} alt="ArrowSpace" className="Home_P6_S2_ArrowHide" />
            </div>
            {/* element five */}
            <div className="Home_P6_S2_Element">
              <div className="HomeP6S2T">+120</div>
              <h3 className="HomeP6S2H3">workforce</h3>
            </div>
          </div>
        </div>
        {/* Home Space */}
        <div className="HomeSpace">
          <SpaceBetweenSec />
        </div>
        {/* Home Part Seven */}
        <div className="HomePartSeven">
          <div className="Home_P7_S1">
            <h1 className="Home_P7_S1_H1">Shaping a Future of Digital Success</h1>
            <p className="Home_P7_S1_P">
              Discover how we're shaping a future where businesses thrive in the digital age. Be part of our mission to empower organizations with
              transformative technology solutions
            </p>
            <Link to="/Contact-us">
              <button className="Home_P7_S1_Button">Join now</button>
            </Link>
          </div>
          <div className="Home_P7_S2">
            <LazyMedia type="image" src={HomeP7Img} alt="HomeP7Img" className="HomeP7Img" />
          </div>
        </div>
        {/* Home Part Eight */}
        <div className="HomePartEight">
          <div className="Home_P8_S1">
            <h1 className="Home_P8_S1_H1">We build solutions for</h1>
          </div>
          <div className="Home_P8_S2">
            <HomePartEightSlider />
          </div>
        </div>
        {/* Home Part Nine */}
        {/* <div className="HomePartNine">
          <TestimonialHome />
        </div> */}
      </div>
    </>
  );
}

export default HomeContent;
