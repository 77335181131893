import React, { useEffect, useState } from "react";
import styles from "./AboutUs.module.css";
// Import SVGs as React components
import { ReactComponent as Pheonixarrow } from "../../assets/svg/phoenixarr.svg";
import { ReactComponent as Arrow } from "../../assets/svg/arrowSeparate.svg";
import { ReactComponent as Dotone } from "../../assets/svg/dotone.svg";
import { ReactComponent as Dottwo } from "../../assets/svg/dottwo.svg";

// Import Images
import AboutUsImage from "../../assets/images/Aboutus.png";
import ClientImage from "../../assets/images/Client.png";
import ProficiencyImage from "../../assets/images/proficiency.png";
import CareImage from "../../assets/images/Care.png";
import EnterprisingImage from "../../assets/images/Enterprising.png";
import OurPeopleCoverImage from "../../assets/images/ourpeoplecover.png";
import ClientHoverImage from "../../assets/images/clientH.png";
import ProficiencyHoverImage from "../../assets/images/proficiencyH.png";
import CareHoverImage from "../../assets/images/careH.png";
import EnterprisingHoverImage from "../../assets/images/EnterprisingH.png";
// Import Icon
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const About = () => {
  const [isFirstContent, setIsFirstContent] = useState(true);

  const getQuote = () => {
    return isFirstContent
      ? "“Here at Phoenix, we live and breathe inclusivity. It's not just a word; it's the essence of our culture. Our leaders and team members thrive on the strength of our diversity.”"
      : "“Phoenix encourages us to step out of our comfort zone and accomplish more than we ever imagined.";
  };

  const toggleQuote = () => {
    setIsFirstContent((prev) => !prev);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      toggleQuote();
    }, 15000);

    return () => clearTimeout(timeoutId);
  }, [isFirstContent]);

  const goToPreviousQuote = () => {
    toggleQuote();
  };

  const goToNextQuote = () => {
    toggleQuote();
  };

  // State to track hover
  const [hoveredImage, setHoveredImage] = useState(null);
  return (
    <div className={styles.AboutUsBig_Container}>
      <div className={styles.SectionOneCover} style={{ backgroundImage: `url(${AboutUsImage})` }}>
        <div className={styles.coverContent}>
          <h1 className="coverContent_H1">About Phoenix</h1>
          <p className="coverContent_P">We stand by your side, no matter the circumstances</p>
        </div>
      </div>

      <div className={styles.SectionTwo}>
        <div className={styles.SectionTwoContent}>
          <h1>WHO WE ARE</h1>
          <p className={styles.first}>
            Phoenix Consulting isn't just an IT solutions provider, we're your trusted partner in optimizing business operations. Since our establishment in
            2013, we have been committed to developing a robust service portfolio that incorporates state-of-the-art technologies and specialized offerings.
          </p>
          <p className={styles.first}>
            Our goal is to provide our clients with the best possible technology experience. As rapidly emerging system integrators and IT solution providers in
            the MENA region, we specialize in delivering cutting-edge ERP and business transformation solutions to both private and public sector organizations.
          </p>
          <p className={styles.first}>
            Operating across Egypt, UAE, Qatar, Oman, and Azerbaijan, we offer seamless technology solutions that align with your digitalization and process
            automation goals, ultimately ensuring maximum efficiency and a strong ROI. Our unwavering dedication is to support your success in the digital era
            by offering innovative solutions that are tailored to your unique needs and challenges.
          </p>
        </div>
      </div>

      <div className={styles.SectionThree}>
        <div className={styles.SectionThree_PartOne}>
          <div className={styles.SectionThree_Header}>
            <div className={styles.arrow}>
              <Pheonixarrow />
            </div>
            <h1 className={styles.Title}>Our Mission </h1>
          </div>

          <div className={styles.ourText}>
            <p>
              We are dedicated to delivering exceptional value to our clients. We achieve this by providing an unparalleled customer experience, collaborating
              closely with our clients to develop tailored solutions that cater to their specific needs. Our commitment extends to conducting all corporate
              activities with unwavering ethical and professional integrity. In doing so, we foster a mutually rewarding environment for all our stakeholders.
            </p>
          </div>
        </div>

        <div className={styles.SectionThree_PartTwo}>
          <div className={styles.SectionThree_Header}>
            <div className={styles.arrow}>
              <Pheonixarrow />
            </div>
            <h1 className={styles.Title}>Our Purpose</h1>
          </div>

          <div className={styles.ourText}>
            <p>
              We aspire to be the preferred global transformation partner for clients who are dedicated to thriving in an ever-evolving world. Our aim is to
              collaboratively shape the future of business, enabling success in an era of constant change.
            </p>
          </div>
        </div>
      </div>

      <Arrow className={styles.line} />

      <div className={styles.SectionFour}>
        <h1 className={styles.whytitle}>Why Phoenix?</h1>

        <div className={styles.SectionFour_Part1}>
          <img
            src={hoveredImage === "client" ? ClientHoverImage : ClientImage}
            alt="Client"
            className={styles.imagesOne}
            onMouseEnter={() => setHoveredImage("client")}
            onMouseLeave={() => setHoveredImage(null)}
          />
          <img
            src={hoveredImage === "proficiency" ? ProficiencyHoverImage : ProficiencyImage}
            alt="Client"
            className={styles.imagesTwo}
            onMouseEnter={() => setHoveredImage("proficiency")}
            onMouseLeave={() => setHoveredImage(null)}
          />
        </div>

        <div className={styles.SectionFour_Part1}>
          <img
            src={hoveredImage === "care" ? CareHoverImage : CareImage}
            alt="Client"
            className={styles.imagesthree}
            onMouseEnter={() => setHoveredImage("care")}
            onMouseLeave={() => setHoveredImage(null)}
          />
          <img
            src={hoveredImage === "enterprising" ? EnterprisingHoverImage : EnterprisingImage}
            alt="Client"
            className={styles.imagesfour}
            onMouseEnter={() => setHoveredImage("enterprising")}
            onMouseLeave={() => setHoveredImage(null)}
          />
        </div>
      </div>

      <div className={styles.SectionFive}>
        <div className={styles.vary}>
          <div className={styles.contentvary}>
            <div className={styles.About_ArrowBackIosIcon} onClick={goToPreviousQuote}>
              <ArrowBackIosIcon />
            </div>

            <div className={styles.quoteContainer}>
              <h4>{getQuote()}</h4>
              <p>Proudly shared by one of our team members.</p>
              <div className={styles.dots}>
                {isFirstContent ? <Dotone /> : <Dottwo />}
                &nbsp;
                {!isFirstContent ? <Dotone /> : <Dottwo />}
              </div>
            </div>

            <div className={styles.About_ArrowForwardIosIcon} onClick={goToNextQuote}>
              <ArrowForwardIosIcon />
            </div>
          </div>
        </div>
      </div>

      <div className={styles.SectionSix} style={{ backgroundImage: `url(${OurPeopleCoverImage})` }}>
        <div className={styles.SectionSix_CoverContent}>
          <h1>Our people</h1>
          <h4>Real results Start with Real leaders</h4>
        </div>
      </div>
      {/*  */}
      {/*  */}
      {/*  */}
      <div className={styles.SectionSeven}>
        <div className={styles.SectionSeven_part1}>
          <h1 className={styles.SectionSeven_Header}> The people make the place, and ours are some of the best in the business</h1>

          <p className={styles.SectionSeven_P1}>
            At Phoenix, we empower our people with the right mindsets and skills to navigate what’s next, become the transformative leaders the world needs,
            pursue careers as unique as they are, and build their own exceptional Phoenix experiences
          </p>

          <p className={styles.SectionSeven_P2}>
            Our more than <span className={styles.smallspan}>120 people </span> leads and inspires others during their time in Phoenix and beyond and brings our
            purpose to life in the work they do every day. The ability to invite, leverage and learn from different perspectives is key to delivering for our
            clients.
          </p>
        </div>

        <div className={styles.SectionSeven_Part2}>
          <h3 className={styles.Part2_Header_1}>We believe</h3>
          <h3 className={styles.Part2_Header_2}>Diversity and inclusion drive growth.</h3>
          <p className={styles.SectionSeven_P3}>
            We are investing more time and money than ever before in skills and learning for our people. which helps our people develop future-focused skills in
            areas such as technology, sustainability and leadership.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
