import React from "react";
import "./caseStudy.css";
import { Link } from "react-router-dom";
import casestudiesXP2One from "../../assets/svg/CaseStudiesX_P2_ImgOne.svg";
import casestudiesXP2Two from "../../assets/svg/CaseStudiesX_P2_ImgTwo.svg";
import casestudiesXP2Three from "../../assets/svg/casestudiesXP2Three.svg";
import casestudiesXP2Arrow from "../../assets/svg/CaseStudiesX_P2_Arrow.svg";
import AmerGroup from "../../assets/images/amer.png";
import Damietta from "../../assets/images/damietta.png";
import ADH from "../../assets/images/ADH-logo.png";
import LazyMedia from "../../lazyLoad/lazyLoad";

function CaseStudyContent() {
  return (
    <>
      <div className="CaseStudies_Container">
        <div className="CaseStudies_FirstPart">
          <div className="CaseStudies_P1_contain">
            <div className="CaseStudies_P1_first">
              <p>Case Studies</p>
            </div>
            <div className="CaseStudies_P1_second">
              <p>Discover how Phoenix can transform your business.</p>
            </div>
            <div className="CaseStudies_P1_third"></div>
          </div>
        </div>
        {/* CaseStudies Part Two */}
        <div className="CaseStudiesMain_SecondPart">
          <div className="CaseStudiesMain_P2_S1">
            <h1 className="CaseStudiesMain_P2_S1_H1">The Power of Case Studies in Business</h1>
            <p className="CaseStudiesMain_P2_S1_P">
              Case studies are commonly used in academic, business, and professional settings to understand and illustrate complex issues.
            </p>
          </div>
          <div className="CaseStudiesMain_P2_S2">
            {/* box 1 HSA */}
            <Link to="/HSA" className="CaseStudiesX_P2_Box_S3_A">
              <div className="CaseStudiesX_P2_Box">
                <div className="CaseStudiesX_P2_Box_S1">
                  <h3 className="CaseStudiesX_P2_Box_S1_H3">HSA GROUP</h3>
                  <p className="CaseStudiesX_P2_Box_S1_P">Building business resilience in challenging times on advanced ERA in the cloud.</p>
                </div>
                <div className="CaseStudiesX_P2_Box_S2">
                  <LazyMedia type="image" src={casestudiesXP2One} alt="casestudiesXP2One" className="casestudiesXP2One" />
                </div>
                <div className="CaseStudiesX_P2_Box_S3">
                  <button className="CaseStudiesX_P2_Box_S3_Button">
                    <p className="CaseStudiesX_P2_Box_S3_Button_P">Read full story</p>
                    <LazyMedia type="image" src={casestudiesXP2Arrow} alt="casestudiesXP2Arrow" className="casestudiesXP2Arrow" />
                  </button>
                </div>
              </div>
            </Link>
            {/* box 2 EGIC */}
            <Link to="/EGIC" className="CaseStudiesX_P2_Box_S3_A">
              <div className="CaseStudiesX_P2_Box">
                <div className="CaseStudiesX_P2_Box_S1">
                  <h3 className="CaseStudiesX_P2_Box_S1_H3">EGIC</h3>
                  <p className="CaseStudiesX_P2_Box_S1_P">EGIC was established to introduce PP-R systems for the water supply in the Egyptian market</p>
                </div>
                <div className="CaseStudiesX_P2_Box_S2">
                  <LazyMedia type="image" src={casestudiesXP2Two} alt="casestudiesXP2Two" className="casestudiesXP2One" />
                </div>
                <div className="CaseStudiesX_P2_Box_S3">
                  <button className="CaseStudiesX_P2_Box_S3_Button">
                    <p className="CaseStudiesX_P2_Box_S3_Button_P">Read full story</p>
                    <LazyMedia type="image" src={casestudiesXP2Arrow} alt="casestudiesXP2Arrow" className="casestudiesXP2Arrow" />
                  </button>
                </div>
              </div>
            </Link>
            {/* box 3 Pharmaoverseas */}
            <Link to="/Pharmaoverseas" className="CaseStudiesX_P2_Box_S3_A">
              <div className="CaseStudiesX_P2_Box">
                <div className="CaseStudiesX_P2_Box_S1">
                  <h3 className="CaseStudiesX_P2_Box_S1_H3">Pharmaoverseas</h3>
                  <p className="CaseStudiesX_P2_Box_S1_P">Pharmaoverseas suppliers, governmental bodies, society, and customers</p>
                </div>
                <div className="CaseStudiesX_P2_Box_S2">
                  <LazyMedia type="image" src={casestudiesXP2Three} alt="casestudiesXP2Three" className="casestudiesXP2One" />
                </div>
                <div className="CaseStudiesX_P2_Box_S3">
                  <button className="CaseStudiesX_P2_Box_S3_Button">
                    <p className="CaseStudiesX_P2_Box_S3_Button_P">Read full story</p>
                    <LazyMedia type="image" src={casestudiesXP2Arrow} alt="casestudiesXP2Arrow" className="casestudiesXP2Arrow" />
                  </button>
                </div>
              </div>
            </Link>
            {/* end boxes */}
          </div>
          <div className="CaseStudiesMain_P2_S2">
            {/* box 4 */}
            <Link to="/AmerGroup" className="CaseStudiesX_P2_Box_S3_A">
              <div className="CaseStudiesX_P2_Box">
                <div className="CaseStudiesX_P2_Box_S1">
                  <h3 className="CaseStudiesX_P2_Box_S1_H3">Amer Group</h3>
                  <p className="CaseStudiesX_P2_Box_S1_P">Amer Group is a leading real estate developer that offers mixed-use, family-oriented destinations.</p>
                </div>
                <div className="CaseStudiesX_P2_Box_S2">
                  <LazyMedia type="image" src={AmerGroup} alt="casestudiesXP2One" className="casestudiesXP2One AmerGroup" />
                </div>
                <div className="CaseStudiesX_P2_Box_S3">
                  <button className="CaseStudiesX_P2_Box_S3_Button">
                    <p className="CaseStudiesX_P2_Box_S3_Button_P">Read full story</p>
                    <LazyMedia type="image" src={casestudiesXP2Arrow} alt="casestudiesXP2Arrow" className="casestudiesXP2Arrow" />
                  </button>
                </div>
              </div>
            </Link>
            {/* box 5 */}
            <Link to="/Damietta" className="CaseStudiesX_P2_Box_S3_A">
              <div className="CaseStudiesX_P2_Box">
                <div className="CaseStudiesX_P2_Box_S1">
                  <h3 className="CaseStudiesX_P2_Box_S1_H3">Damietta Alliance</h3>
                  <p className="CaseStudiesX_P2_Box_S1_P">The Port of Damietta is one of the main ports in Egypt, close to the River Nile</p>
                </div>
                <div className="CaseStudiesX_P2_Box_S2">
                  <LazyMedia type="image" src={Damietta} alt="casestudiesXP2One" className="casestudiesXP2One Damietta" />
                </div>
                <div className="CaseStudiesX_P2_Box_S3">
                  <button className="CaseStudiesX_P2_Box_S3_Button">
                    <p className="CaseStudiesX_P2_Box_S3_Button_P">Read full story</p>
                    <LazyMedia type="image" src={casestudiesXP2Arrow} alt="casestudiesXP2Arrow" className="casestudiesXP2Arrow" />
                  </button>
                </div>
              </div>
            </Link>
            {/* box 6 */}
            <Link to="/ADH" className="CaseStudiesX_P2_Box_S3_A">
              <div className="CaseStudiesX_P2_Box">
                <div className="CaseStudiesX_P2_Box_S1">
                  <h3 className="CaseStudiesX_P2_Box_S1_H3 SLine">Arab Developers Holding</h3>
                  <p className="CaseStudiesX_P2_Box_S1_P">Capturing the untapped potential and delivering healthy returns for clients</p>
                </div>
                <div className="CaseStudiesX_P2_Box_S2">
                  <LazyMedia type="image" src={ADH} alt="casestudiesXP2One" className="casestudiesXP2One Damietta" />
                </div>
                <div className="CaseStudiesX_P2_Box_S3">
                  <button className="CaseStudiesX_P2_Box_S3_Button">
                    <p className="CaseStudiesX_P2_Box_S3_Button_P">Read full story</p>
                    <LazyMedia type="image" src={casestudiesXP2Arrow} alt="casestudiesXP2Arrow" className="casestudiesXP2Arrow" />
                  </button>
                </div>
              </div>
            </Link>
          </div>
          <div className="None"></div>
        </div>
      </div>
    </>
  );
}

export default CaseStudyContent;
