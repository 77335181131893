import React, { useState } from "react";
import "./AwsSecondPartInfo.css";
import AwsP2Transformation from "../../../assets/images/Aws_P2_Transformation.webp";
import AwsP2Migration from "../../../assets/svg/Aws_P2_Migration.webp";
import AwsP2Cloud from "../../../assets/svg/Aws_P2_Cloud.webp";
import LazyMedia from "../../../lazyLoad/lazyLoad";

function AwsSecondPartInfo() {
  const [activeButton, setActiveButton] = useState(1);

  const AwsSecondPartInfoContents = [
    {
      id: "AwsOne",
      name: "AWS Transformation Services",
      h3: "Revamp Business Models with Accelerated Cloud Adoption ",
      paragraph: [
        "As businesses shift to cloud-first approaches, they require expert support for flexible, scalable, and cost-effective IT infrastructure. Phoenix and AWS collaborate, adhering to the AWS Cloud Transformation Maturity Model, ensuring secure and transformative cloud adoption.",
        " With vast experience in complex global cloud transformations, we tailor each step to help organizations achieve their vision of successful digital transformation.",
      ],
      img: AwsP2Transformation,
    },
    {
      id: "AwsTwo",
      name: "AWS Migration Services",
      h3: "Seamless Cloud Migration Solutions Tailored for Your Success.",
      paragraph: [
        "Phoenix offers consulting, migration, and management expertise for your cloud migration. We focus on various areas, including workload, data center, SAP, data and database migration, modernization, enterprise platform migration, disaster recovery, and transitioning from other cloud providers to AWS.",
        " Our managed services follow a flexible, multi-phase approach, ensuring a successful migration supported by tools like Migration accelerators. ",
      ],
      img: AwsP2Migration,
    },
    {
      id: "AwsThree",
      name: "Managed AWS Cloud Services",
      h3: "Automated management ensures secure, compliant AWS environments.",
      paragraph: [
        "Companies have high expectations when transitioning to the cloud, but they often face challenges, including risky configurations and cost management .",
        " Phoenix AWS Managed Services simplifies the process by offering a secure AWS Landing Zone, an established enterprise operating model, cost optimization, and ongoing infrastructure management.",
        " We assist in finding the right balance between technology and practicality to boost agility and collaboration in your IT environment.",
      ],
      img: AwsP2Cloud,
    },
    {
      id: "AwsFour",
      name: "SAP workloads migration on AWS",
      h3: "Enhance your business by migrating SAP to AWS for optimization and transformation.",
      paragraph: [
        "Enhance your business by migrating SAP to AWS for optimization and transformation. organizations are investing in transforming their SAP landscape by migrating to AWS, enabling the deployment of SAP workloads and the creation of secure, robust cloud-based platforms. This shift helps organizations become modern, digital, and intelligent enterprises.",
        "Phoenix, SAP, and AWS collaborate to provide customized services and solutions for various industries. As a global SAP service provider and AWS SAP Competency Partner, Phoenix offers specialized services and tools to streamline your SAP migratio journey to AWS.",
      ],

      img: AwsP2Migration,
    },
  ];

  return (
    <div className="AwsSecondPartInfoContainer">
      <Buttons contents={AwsSecondPartInfoContents} activeButton={activeButton} setActiveButton={setActiveButton} />
      <ContentDisplay content={AwsSecondPartInfoContents[activeButton - 1]} />
    </div>
  );
}

function Buttons({ contents, activeButton, setActiveButton }) {
  return (
    <div className="AwsSecondPartInfo_container">
      {contents.map((content, index) => (
        <div
          key={index}
          className={`AwsSecondPartInfo_button ${activeButton === index + 1 ? "AwsSecondPartInfo_active" : ""}`}
          onClick={() => setActiveButton(index + 1)}
        >
          {content.name}
        </div>
      ))}
    </div>
  );
}

function ContentDisplay({ content }) {
  return (
    <div className="AwsSecondPartInfo_content">
      <div className="Awscontent_MoreInfo_container">
        <h3>{content.h3}</h3>
        {content.paragraph.map((text, index) => (
          <p key={index} className="Awscontent_MoreInfo_container_P">
            {text}
          </p>
        ))}
        {content.id === "AwsFour" && content.dotslist && (
          <ul className="Awscontent_MoreInfo_dotslist">
            {content.dotslist.map((item, index) => (
              <li key={index} className="Awscontent_MoreInfo_list_item">
                <strong>{item[0]}</strong>
                <p>{item[1]}</p>
              </li>
            ))}
          </ul>
        )}
      </div>
      <div className="AwsImg_MoreInfo">{content.img && <LazyMedia type="image" src={content.img} alt="Img" className="Img_AWS_Second" />}</div>
    </div>
  );
}

export default AwsSecondPartInfo;
