import React from "react";
import "./Azure.css";
import { Link } from "react-router-dom";
import AzureInfo from "../../components/hooks/AzurePartThreeInfo/AzurePartThreeInfo";
import AzurePartSixBoxH from "../../components/hooks/AzurePartSixBox/AzurePartSixBox";
import AzureStar from "../../assets/svg/SapStar.svg";
import AzurePlus from "../../assets/svg/Sap_plus.svg";
import AzureLogo from "../../assets/images/Home/MicrosoftAzureLogo.png";
import AzureP2S2Img from "../../assets/images/Azure_P2_S2.jpg";
import AzureP2S3Img from "../../assets/svg/Azure_P2_S3.svg";
import AzureP3S2Img from "../../assets/images/AzureP3S2Img.jpg";
import AzureP7S2Img1 from "../../assets/images/Azure_P7_S2_Img1.jpg";
import AzureP7S2Img2 from "../../assets/images/Azure_P7_S2_Img2.jpg";
import AzureP7S2Img3 from "../../assets/svg/Azure_P7_S2_Img3.svg";
import AzureP8S2Img from "../../assets/images/AzureP8S2Img.jpg";
import PartnersBanner from "../../assets/videos/Partners_Banner.MP4";
import LazyMedia from "../../lazyLoad/lazyLoad";

function AzureContent() {
  return (
    <>
      <div className="AzureContent_Container">
        {/* Azure Part 1 */}
        <div className="Azure_FirstPart">
          <LazyMedia type="video" src={PartnersBanner} className="Partners_Azure_VideoBackground" />
          <LazyMedia type="image" src={AzureStar} alt="AzureStar" className="AzureStar" />
          <div className="Azure_P1_S1">
            <h1 className="Azure_P1_S1_H1">Phoenix</h1>
            <LazyMedia type="image" src={AzurePlus} alt="AzurePlus" />
            <LazyMedia type="image" src={AzureLogo} alt="AzureLogo" className="AzureLogo" />
          </div>
          <h1 className="Azure_FirstPart_H1">Your Premier Partner for Cloud Excellence</h1>
          <p className="Azure_FirstPart_P">
            Phoenix Consulting is your top-tier partner for unlocking the power of Microsoft Azure. We're leaders in cloud innovation, committed to delivering
            future-proof solutions. Our expertise transforms enterprises, offering customized strategies for seamless integration. We provide comprehensive
            Azure services, manage SAP to Azure migrations, and ensure flexibility, security, and strategic guidance. Choose Phoenix Consulting for cloud
            excellence.
          </p>
        </div>
        {/* Azure Part 2 */}
        <div className="Azure_SecondPart">
          <div className="Azure_P2_S1">
            <h1 className="Azure_P2_S1_H1">Leading Innovation with Azure's Dynamic Cloud Solutions</h1>
          </div>
          <div className="Azure_P2_S2">
            <LazyMedia type="image" src={AzureP2S2Img} alt="AzureImg" className="AzureP2S2Img" />
          </div>
          <div className="Azure_P2_S3">
            <LazyMedia type="image" src={AzureP2S3Img} alt="AzureImg2" className="AzureP2S3Img" />
            <p className="Azure_P2_S3_P">
              In a rapidly advancing digital world, the key to staying competitive lies in harnessing powerful and robust cloud platforms like Microsoft Azure.
              Phoenix Consulting's alliance with Azure is built on a foundation of innovation, technological excellence, and a profound commitment to providing
              future-proof cloud solutions. As a leader in the tech industry, we are adept at leveraging the extensive range of Azure services to deliver
              comprehensive solutions tailored to maintain and enhance your competitive edge.
            </p>
          </div>
        </div>
        {/* Azure Part 3 */}
        <div className="Azure_ThirdPart">
          <div className="Azure_P3_S1">
            <h1 className="Azure_P3_S1_H1">Transforming Enterprises with Azure</h1>
            <p className="Azure_P3_S1_P">
              Our expertise in Azure's cloud environment enables us to facilitate your digital transformation. We guide you through every stage of cloud
              adoption, migration, and ongoing management with a suite of services that promotes sustainable growth, modernization, and the optimization of your
              IT infrastructure within Azure's secure and adaptable framework.
            </p>
          </div>
          <div className="Azure_P3_S2">
            <LazyMedia type="image" src={AzureP3S2Img} alt="AzureImg3" className="AzureP3S2Img" />
          </div>
        </div>
        {/* Azure Part 4 */}
        <div className="Azure_FourthPart">
          <div className="Azure_P4_S1">
            <h1 className="Azure_P4_S1_H1">Customized Azure Strategies for Seamless Integration</h1>
          </div>
          <div className="Azure_P4_S2">
            <p className="Azure_P4_S2_P">
              Phoenix Consulting offers a strategic partnership that extends beyond the conventional. With Azure's global network of managed data centers, we
              provide not just secure and reliable cloud services but also a spectrum of tools designed to cater to your unique business needs
            </p>
          </div>
          <div className="Azure_P4_S3">
            {/* Box 1 */}
            <div className="Azure_P4_S3_Box">
              <h3 className="Azure_P4_S3_Box_H1">Scalable Computing</h3>
              <p className="Azure_P4_S3_Box_P">Azure Virtual Machines adapt to your computing needs with ease.</p>
            </div>
            {/* Box 2 */}
            <div className="Azure_P4_S3_Box">
              <h3 className="Azure_P4_S3_Box_H1">Intelligent SQL Management</h3>
              <p className="Azure_P4_S3_Box_P">Azure SQL Database delivers fully managed, intelligent SQL in the cloud.</p>
            </div>
            {/* Box 3 */}
            <div className="Azure_P4_S3_Box">
              <h3 className="Azure_P4_S3_Box_H1">Diverse Data Storage</h3>
              <p className="Azure_P4_S3_Box_P">Azure Cosmos DB, a multi-model database service, distributes your data globally.</p>
            </div>
            {/* Box 4 */}
            <div className="Azure_P4_S3_Box">
              <h3 className="Azure_P4_S3_Box_H1">Secure Access Management</h3>
              <p className="Azure_P4_S3_Box_P">Azure Active Directory manages user identities and access.</p>
            </div>
            {/* Box 5 */}
            <div className="Azure_P4_S3_Box">
              <h3 className="Azure_P4_S3_Box_H1">Data Capture & Analysis</h3>
              <p className="Azure_P4_S3_Box_P">The Azure IoT Suite unlocks insights from untapped data sources.</p>
            </div>
            {/* Box 6 */}
            <div className="Azure_P4_S3_Box">
              <h3 className="Azure_P4_S3_Box_H1">Innovative AI & Machine Learning</h3>
              <p className="Azure_P4_S3_Box_P">Azure's AI and ML services enable the creation of smart solutions.</p>
            </div>
            {/* Box 7 */}
            <div className="Azure_P4_S3_Box">
              <h3 className="Azure_P4_S3_Box_H1">Collaborative Development</h3>
              <p className="Azure_P4_S3_Box_P">Azure DevOps supports a cooperative software development environment.</p>
            </div>
          </div>
        </div>
        {/* Azure Part 5 */}
        <div className="Azure_FifthPart">
          <div className="Azure_P5_S1">
            <h1 className="Azure_P5_S1_H1">Comprehensive Azure Cloud Services for Your Business</h1>
            <p className="Azure_P5_S1_P">
              Phoenix Consulting is your navigator in the Azure ecosystem, ensuring that your transition to the cloud is seamless and strategic. Our services
              include
            </p>
          </div>

          <div className="Azure_P5_S2">
            <AzureInfo />
          </div>
        </div>
        {/* Azure Part 6 */}
        <div className="Azure_SixthPart">
          <div className="Azure_P6_S1">
            <h1 className="Azure_P6_S1_H1">Unmatched Expertise in SAP to Azure Migrations</h1>
            <p className="Azure_P6_S1_P">
              SAP S/4HANA is the perfect example of modern ERP systems that are designed to meet the demands of today's constantly evolving business landscape.
              Here's why choosing SAP S/4HANA is a strategic move
            </p>
          </div>
          <div className="Azure_P6_S2">
            <AzurePartSixBoxH />
          </div>
        </div>
        {/* Azure Part 7 */}
        <div className="Azure_SevenPart">
          <div className="Azure_P7_S1">
            <h1 className="Azure_P7_S1_H1">Why Azure & Phoenix Consulting Are the Right Choice</h1>
          </div>
          <div className="Azure_P7_S2">
            {/* Element one */}
            <div className="Azure_P7_S2_E">
              <LazyMedia type="image" src={AzureP7S2Img1} alt="AzureP7S2Img" className="AzureP7S2Img1" />
              <h3 className="Azure_P7_S2_H3">Flexibility & Agility</h3>
              <p className="Azure_P7_S2_P">Azure enables rapid growth and adaptation, providing your business with the agility to excel.</p>
            </div>
            {/* Element Two */}
            <div className="Azure_P7_S2_E">
              <LazyMedia type="image" src={AzureP7S2Img2} alt="AzureP7S2Img" className="AzureP7S2Img2" />
              <h3 className="Azure_P7_S2_H3">Security & Compliance</h3>
              <p className="Azure_P7_S2_P">With Azure, you enjoy a secure, compliant cloud environment that meets rigorous regulatory standards.</p>
            </div>{" "}
            {/* Element Three */}
            <div className="Azure_P7_S2_E">
              <LazyMedia type="image" src={AzureP7S2Img3} alt="AzureP7S2Img" />
              <h3 className="Azure_P7_S2_H3">Planning & Industry Expertise</h3>
              <p className="Azure_P7_S2_P">Our team's decades of leadership in technology assure you of strategic guidance and innovative cloud strategies.</p>
            </div>
          </div>
        </div>
        {/* Azure Part 8 */}
        <div className="Azure_EightPart">
          <div className="Azure_P8_S1">
            <h1 className="Azure_P8_S1_H1">Forward Together</h1>
            <p className="Azure_P8_S1_P">
              As Phoenix Consulting grows, we not only expand our service capabilities but also share our wealth of knowledge through certified Azure training
              programs. Our mission is to build an ecosystem of growth, knowledge, and technology that propels every client towards their vision.
            </p>
            <Link to="/contact-us">
              <button className="Azure_P8_S1_Button">Contact us</button>
            </Link>
          </div>
          <div className="Azure_P8_S2">
            <LazyMedia type="image" src={AzureP8S2Img} alt="AzureP8S2Img" className="AzureP8S2Img" />
          </div>
        </div>
      </div>
    </>
  );
}

export default AzureContent;
