import React from "react";
import "./caseStudy_EGIC.css";
import { Link } from "react-router-dom";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import CaseStudies_P3_S1 from "../../assets/images/Egic-Pdf.jpg";
import SapLogo from "../../assets/svg/Sap_SapBlue.svg";
import AwsLogo from "../../assets/images/Aws_Logo_For_Case.jpg";
import MainLogo from "../../assets/svg/Logo.svg";
import caseStudiesP4 from "../../assets/images/caseStudies_P4.png";
import caseStudiesP5 from "../../assets/images/caseStudies_P5.png";
import casestudiesXP2One from "../../assets/svg/CaseStudiesX_P2_ImgOne.svg";
import casestudiesXP2Two from "../../assets/svg/CaseStudiesX_P2_ImgTwo.svg";
import casestudiesXP2Three from "../../assets/svg/casestudiesXP2Three.svg";
import casestudiesXP2Arrow from "../../assets/svg/CaseStudiesX_P2_Arrow.svg";
import AmerGroup from "../../assets/images/amer.png";
import Damietta from "../../assets/images/damietta.png";
import ADH from "../../assets/images/ADH-logo.png";
import LazyMedia from "../../lazyLoad/lazyLoad";

function CaseStudyEGICContent() {
  return (
    <>
      <div className="caseStudyEGICContainer">
        {/* First Part */}
        <div className="CaseStudyEGICFirstPart">
          <div className="EGIC_P1_S1">
            <Link to="/case-Studies">
              <h3 className="EGIC_P1_S1_H3">Case Studies</h3>
            </Link>
            <KeyboardArrowLeftIcon className="KeyboardArrowLeftIcon" />
            <Link to="/EGIC">
              <h3 className="EGIC_P1_S1_H3">EGIC</h3>
            </Link>
          </div>
          <div className="EGIC_P1_S2_Container">
            <div className="EGIC_P1_S2">
              <h1 className="EGIC_P1_H1">EGIC GROUP</h1>

              <h3 className="EGIC_P1_H3">
                EGIC’s focus on producing wide ranges of products with superior German quality has placed it as a trendsetter and market leader in the plumbing
                industry where we provide complete home solutions for different residential/industrial projects across the world.
              </h3>
              <p className="EGIC_P1_P">
                In 1991, Egyptian German Industrial Corporate – EGIC was established to introduce PP-R systems for the water supply in the Egyptian market, then
                expanded its entire production to produce and market all types of reliable plumbing and sanitary products.
              </p>
            </div>
          </div>
        </div>
        {/* Second Part */}
        {/* Second Part */}
        <div className="EGIC_P2_S1">
          <div>
            <LazyMedia type="image" src={casestudiesXP2Two} alt="casestudiesXP2Two" className="casestudiesXP2OneWeb" />
          </div>
          <div className="EGIC_P2_S1_Container">
            <h1 className="EGIC_P2_S1_H1">
              In 1991, Egyptian German Industrial Corporate – EGIC was established to introduce PP-R systems for the water supply in the Egyptian market, then
              expanded its entire production to produce and market all types of reliable plumbing and sanitary products.
            </h1>
            <LazyMedia type="image" src={casestudiesXP2Two} alt="casestudiesXP2Two" className="casestudiesXP2OneMobile" />
          </div>
        </div>
        {/* third part */}
        <div className="CaseStudies_FifthPart">
          <div className="CaseStudies_P5_Content1">
            {/* first part */}
            <div className="Case_P5_C1_Container">
              <div className="Case_P5_C1_Text">
                <div>
                  <h3 className="CaseStudies_P5_C1_H3">About EGIC</h3>
                </div>
                <div className="spaceLine"></div>
                <div>
                  <p className="CaseStudies_P5_C1_P">
                    In 1991, Egyptian German Industrial Corporate – EGIC was established to introduce PP-R systems for the water supply in the Egyptian market,
                    then expanded its entire production to produce and market all types of reliable plumbing and sanitary products.
                  </p>
                  <p className="CaseStudies_P5_C1_P">
                    EGIC’s focus on producing wide ranges of products with superior German quality has placed it as a trendsetter and market leader in the
                    plumbing industry where we provide complete home solutions for different residential/industrial projects across the world.
                  </p>
                </div>
                <div>
                  <h3 className="CaseStudies_P5_C1_H3">Executive Summary:</h3>
                </div>
                <div className="spaceLine"></div>
                <div>
                  <p className="CaseStudies_P5_C1_P">
                    EGIC has established top-class manufacturing facilities; four in Egypt and one in Germany, where we produce water supply and drainage
                    solutions from several plastic materials and copper (polypropylene, PVC, and polyethylene) under the best-known brands; Bänninger, Kessel
                    and Smart Home. One of those four factories is the largest copper foundry in the Middle East that manufactures the purest bronze bars,
                    valves, and other related accessories.
                  </p>
                  <p className="CaseStudies_P5_C1_P">
                    Through lean manufacturing and High-tech machinery supported by the SAP control, EGIC’s facilities are considered to be one of the highest
                    efficient and certified facilities within the middle-east
                  </p>
                  <p className="CaseStudies_P5_C1_P">
                    EGIC wanted to upgrade its on-premises SAP systems to latest SAP ERP on HANA and saw this as a good opportunity to also make a strategic
                    move to Amazon Web Services (AWS) to innovate faster and create more value for the business. It also wanted to be able to adapt more quickly
                    to business and market needs.
                  </p>
                  <p className="CaseStudies_P5_C1_P">
                    Phoenix worked to demonstrate
                    <strong className="CaseStudies_P5_C1_P_Strong">AWS Migration Acceleration Program</strong>
                    (MAP) to EGIC, which helps to accelerate the cloud migration and modernization journey with an outcome-driven methodology, was an important
                    factor in EGIC’s decision making.
                  </p>
                </div>
                {/* logo at the end of paper */}
                <div className="CaseStudies_P5_C1_Logo">
                  <LazyMedia type="image" src={MainLogo} alt="MainLogo" className="CaseMainLogo" />
                  <LazyMedia type="image" src={AwsLogo} alt="SapLogo" className="CaseAwsLogo" />
                  <LazyMedia type="image" src={SapLogo} alt="SapLogo" className="CaseSapLogo" />
                </div>
              </div>
              {/* image right */}
              <div className="CaseStudies_P5_C1_Img">
                <LazyMedia type="image" src={CaseStudies_P3_S1} alt="" className="CaseStudies_P5_C1_ImgWeb" />
              </div>
            </div>
            {/* second part */}
            <div className="Case_P5_C1_Container">
              <div className="Case_P5_C1_Text">
                <div>
                  <h3 className="CaseStudies_P5_C1_H3">Before: Challenges and Opportunities</h3>
                </div>
                <div className="spaceLine"></div>
                <div className="Case_P5_C1_Text_Ul">
                  {/* point list one */}
                  <div className="Case_P5_C1_Text_Ul_Main">
                    <h4>Before: Challenges and Opportunities</h4>
                    <ul className="Case_P5_C1_Text_Ul_Container">
                      <li>
                        Digitalize operations to build business resilience, leveraging new technologies to promote economic growth as well as delivering a
                        complete home solution with superior German quality, reliable after-sale service, and appropriate distribution networks. (Over 40,000
                        free inspection visits per month)
                      </li>
                      <li>Automate business processes to increase efficiencies and lower costs</li>
                      <li>Gain data visibility across the organization and improve reporting</li>
                      <li>Migrating SAP system workload from IBM Power Servers / Oracle Database on premises datacenter to SAP RISE / HANA on AWS Cloud</li>
                      <li>Optimize the current system Database and enhance performance</li>
                      <li> Cyber security for the SAP systems application </li>
                    </ul>
                  </div>
                  {/* point list two */}
                  <div className="Case_P5_C1_Text_Ul_Main">
                    <h4>Target Architectural Diagram </h4>
                    <p className="Case_P5_C1_Right_P5_P1">
                      Phoenix will work to create the AWS Transit Gateway in EGIC AWS account and share it with the SAP managed AWS account. Afterwards, SAP can
                      attach the VPC for the managed SAP service to the AWS Transit Gateway and enable traffic flow through an entry in the route table. With
                      this setup keep control about traffic routing, because the AWS Transit Gateway resides in EGIC own account where it can be managed
                    </p>
                  </div>
                  {/* point list three */}
                  <div className="Case_P5_C1_Text_Ul_Main">
                    <h4>After: Value-Driven Results</h4>
                    <ul className="Case_P5_C1_Text_Ul_Container">
                      <li> Digitally transformed operations.</li>
                      <li>80% Improvement in system uptime and performance</li>
                      <li>100% Reduction in hardware support, maintenance, and upgrade costs</li>
                      <li>20% Reduction in total cost of IT ownership expected</li>
                      <li>20% Improvement in business processes using real-timedata and connected systems</li>
                      <li>
                        With minimal disruption challenges, Phoenix Consulting worked to digital transformation the current SAP system. Go-live in 1 month to
                        migrate to SAP on AWS
                      </li>
                      <li>Enhanced business efficiency, visibility, and productivity while automating reporting and compliance</li>
                      <li>
                        Achieving the highest profitability, sustainability, and transparency with EGIC’s shareholders enables us to limit our expenses, make
                        the right decisions and increase productivity.
                      </li>
                      <li>Reduced maintenance and total cost of ownership</li>
                      <li>Enabled the reinvestment of its server upgrade and maintenance budget to the business</li>
                      <li>
                        Readied the business for the future with scalable and flexible technology that allows agile operations based on data-driven decisions
                      </li>
                      <li>Improve security posture of workloads in the cloud by way of cloud native security tools/services</li>
                      <li>Meeting stringent RPO and RTO with multi-AZ Server deployment</li>
                    </ul>
                  </div>
                </div>
                <div className="CaseStudies_P5_C1_Logo">
                  <LazyMedia type="image" src={MainLogo} alt="MainLogo" className="CaseMainLogo" />
                  <LazyMedia type="image" src={AwsLogo} alt="SapLogo" className="CaseAwsLogo" />
                  <LazyMedia type="image" src={SapLogo} alt="SapLogo" className="CaseSapLogo" />
                </div>
              </div>
              <div className="CaseStudies_P5_C1_Right">
                <LazyMedia type="image" src={caseStudiesP5} alt="caseStudiesP5" className="CaseStudies_P5_C1_Right_ImgWeb" />
              </div>
            </div>
          </div>
        </div>
        {/* third Part */}
        <div className="CaseStudiesMain_P2_S2">
          {/* box 1 */}
          <Link to="/HSA" className="CaseStudiesX_P2_Box_S3_A">
            <div className="CaseStudiesX_P2_Box">
              <div className="CaseStudiesX_P2_Box_S1">
                <h3 className="CaseStudiesX_P2_Box_S1_H3">HSA GROUP</h3>
                <p className="CaseStudiesX_P2_Box_S1_P">Building business resilience in challenging times on advanced ERA in the cloud.</p>
              </div>
              <div className="CaseStudiesX_P2_Box_S2">
                <LazyMedia type="image" src={casestudiesXP2One} alt="casestudiesXP2One" className="casestudiesXP2One" />
              </div>
              <div className="CaseStudiesX_P2_Box_S3">
                <button className="CaseStudiesX_P2_Box_S3_Button">
                  <p className="CaseStudiesX_P2_Box_S3_Button_P">Read full story</p>
                  <LazyMedia type="image" src={casestudiesXP2Arrow} alt="casestudiesXP2Arrow" className="casestudiesXP2Arrow" />
                </button>
              </div>
            </div>
          </Link>
          {/* box 2 */}
          <Link to="/AmerGroup" className="CaseStudiesX_P2_Box_S3_A">
            <div className="CaseStudiesX_P2_Box">
              <div className="CaseStudiesX_P2_Box_S1">
                <h3 className="CaseStudiesX_P2_Box_S1_H3">Amer Group</h3>
                <p className="CaseStudiesX_P2_Box_S1_P">Amer Group is a leading real estate developer that offers mixed-use, family-oriented destinations.</p>
              </div>
              <div className="CaseStudiesX_P2_Box_S2">
                <LazyMedia type="image" src={AmerGroup} alt="casestudiesXP2One" className="casestudiesXP2One AmerGroup" />
              </div>
              <div className="CaseStudiesX_P2_Box_S3">
                <button className="CaseStudiesX_P2_Box_S3_Button">
                  <p className="CaseStudiesX_P2_Box_S3_Button_P">Read full story</p>
                  <LazyMedia type="image" src={casestudiesXP2Arrow} alt="casestudiesXP2Arrow" className="casestudiesXP2Arrow" />
                </button>
              </div>
            </div>
          </Link>
          {/* box 3 */}
          <Link to="/Pharmaoverseas" className="CaseStudiesX_P2_Box_S3_A">
            <div className="CaseStudiesX_P2_Box">
              <div className="CaseStudiesX_P2_Box_S1">
                <h3 className="CaseStudiesX_P2_Box_S1_H3">Pharmaoverseas</h3>
                <p className="CaseStudiesX_P2_Box_S1_P">Pharmaoverseas suppliers, governmental bodies, society, and customers</p>
              </div>
              <div className="CaseStudiesX_P2_Box_S2">
                <LazyMedia type="image" src={casestudiesXP2Three} alt="casestudiesXP2Three" className="casestudiesXP2One" />
              </div>
              <div className="CaseStudiesX_P2_Box_S3">
                <button className="CaseStudiesX_P2_Box_S3_Button">
                  <p className="CaseStudiesX_P2_Box_S3_Button_P">Read full story</p>
                  <LazyMedia type="image" src={casestudiesXP2Arrow} alt="casestudiesXP2Arrow" className="casestudiesXP2Arrow" />
                </button>
              </div>
            </div>
          </Link>
        </div>
        <div className="CaseStudiesMain_P2_S2">
          {/* box 5 */}
          <Link to="/Damietta" className="CaseStudiesX_P2_Box_S3_A">
            <div className="CaseStudiesX_P2_Box">
              <div className="CaseStudiesX_P2_Box_S1">
                <h3 className="CaseStudiesX_P2_Box_S1_H3">Damietta Alliance</h3>
                <p className="CaseStudiesX_P2_Box_S1_P">The Port of Damietta is one of the main ports in Egypt, close to the River Nile</p>
              </div>
              <div className="CaseStudiesX_P2_Box_S2">
                <LazyMedia type="image" src={Damietta} alt="casestudiesXP2One" className="casestudiesXP2One Damietta" />
              </div>
              <div className="CaseStudiesX_P2_Box_S3">
                <button className="CaseStudiesX_P2_Box_S3_Button">
                  <p className="CaseStudiesX_P2_Box_S3_Button_P">Read full story</p>
                  <LazyMedia type="image" src={casestudiesXP2Arrow} alt="casestudiesXP2Arrow" className="casestudiesXP2Arrow" />
                </button>
              </div>
            </div>
          </Link>
          {/* box 6 */}
          <Link to="/ADH" className="CaseStudiesX_P2_Box_S3_A">
            <div className="CaseStudiesX_P2_Box">
              <div className="CaseStudiesX_P2_Box_S1">
                <h3 className="CaseStudiesX_P2_Box_S1_H3 SLine">Arab Developers Holding</h3>
                <p className="CaseStudiesX_P2_Box_S1_P">Capturing the untapped potential and delivering healthy returns for clients</p>
              </div>
              <div className="CaseStudiesX_P2_Box_S2">
                <LazyMedia type="image" src={ADH} alt="casestudiesXP2One" className="casestudiesXP2One Damietta" />
              </div>
              <div className="CaseStudiesX_P2_Box_S3">
                <button className="CaseStudiesX_P2_Box_S3_Button">
                  <p className="CaseStudiesX_P2_Box_S3_Button_P">Read full story</p>
                  <LazyMedia type="image" src={casestudiesXP2Arrow} alt="casestudiesXP2Arrow" className="casestudiesXP2Arrow" />
                </button>
              </div>
            </div>
          </Link>
        </div>
        <div className="None"></div>
      </div>
    </>
  );
}

export default CaseStudyEGICContent;
