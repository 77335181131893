import React from "react";
import "./caseStudy_Pharmaoverseas.css";
import { Link } from "react-router-dom";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import CaseStudies_P3_S1 from "../../assets/images/Pharmaoverseas_Pdf.png";
import SapLogo from "../../assets/svg/Sap_SapBlue.svg";
import AwsLogo from "../../assets/images/Aws_Logo_For_Case.jpg";
import MainLogo from "../../assets/svg/Logo.svg";
import caseStudiesP4 from "../../assets/images/caseStudies_P4.png";
import casestudiesXP2One from "../../assets/svg/CaseStudiesX_P2_ImgOne.svg";
import casestudiesXP2Two from "../../assets/svg/CaseStudiesX_P2_ImgTwo.svg";
import casestudiesXP2Three from "../../assets/svg/casestudiesXP2Three.svg";
import casestudiesXP2Arrow from "../../assets/svg/CaseStudiesX_P2_Arrow.svg";
import AmerGroup from "../../assets/images/amer.png";
import Damietta from "../../assets/images/damietta.png";
import ADH from "../../assets/images/ADH-logo.png";
import LazyMedia from "../../lazyLoad/lazyLoad";

function CaseStudyPharmaoverseasContent() {
  return (
    <>
      <div className="caseStudyPharmaoverseasContainer">
        {/* First Part */}
        <div className="CaseStudyPharmaoverseasFirstPart">
          <div className="Pharmaoverseas_P1_S1">
            <Link to="/case-Studies">
              <h3 className="Pharmaoverseas_P1_S1_H3">Case Studies</h3>
            </Link>
            <KeyboardArrowLeftIcon className="KeyboardArrowLeftIcon" />
            <Link to="/Pharmaoverseas">
              <h3 className="Pharmaoverseas_P1_S1_H3">Pharmaoverseas</h3>
            </Link>
          </div>
          <div className="Pharmaoverseas_P1_S2_Container">
            <div className="Pharmaoverseas_P1_S2">
              <h1 className="Pharmaoverseas_P1_H1">Pharmaoverseas GROUP</h1>

              <h3 className="Pharmaoverseas_P1_H3">
                We are committed to providing our customers and suppliers in the Egyptian drug market with efficient, high-quality services using the latest
                technologies, through a well-trained and enthusiastic team, aiming to maximize the value for all of our stakeholders.
              </h3>
              <p className="Pharmaoverseas_P1_P">
                In 2011, it was announced as a closed shareholders company. Pharmaoverseas has more than 40 branches include 4 main stores covering all
                territories of Egypt with more than 81,000 m2 warehousing areas.
              </p>
            </div>
          </div>
        </div>
        {/* second part */}
        <div className="Pharmaoverseas_P2_S1">
          <div>
            <LazyMedia type="image" src={casestudiesXP2Three} alt="casestudiesXP2Three" className="casestudiesXP2OneWeb" />
          </div>
          <div className="Pharmaoverseas_P2_S1_Container">
            <h1 className="Pharmaoverseas_P2_S1_H1">
              Pharmaoverseas was founded as a distribution company in 1995 by
              <strong> Dr. Ahmad Gazzarine</strong>, the Company started with one branch in Alexandria then it has been expanded to cover all Governorates of
              Egypt. In July 2004, SERPYMEX the second largest distribution company in France acquired 20 % of Pharmaoverseas.
            </h1>
            <LazyMedia type="image" src={casestudiesXP2Three} alt="casestudiesXP2Three" className="PharmaoverseasP2OneMobile" />
          </div>
        </div>
        {/* Third Part */}
        <div className="CaseStudies_FourthPart">
          <div className="CaseStudies_P4_Content1">
            {/* first part */}
            <div className="Case_P4_C1_Container">
              <div className="Case_P4_C1_Text">
                <div>
                  <h3 className="CaseStudies_P4_C1_H3">About Pharmaoverseas</h3>
                </div>
                <div className="spaceLine"></div>
                <div>
                  <p className="CaseStudies_P4_C1_P">
                    Pharmaoverseas was founded as a distribution company in 1995 by Dr. Ahmad Gazzarine, the Company started with one branch in Alexandria then
                    it has been expanded to cover all Governorates of Egypt. In July 2004, SERPYMEX the second largest distribution company in France acquired
                    20 % of Pharmaoverseas.
                  </p>
                  <p className="CaseStudies_P4_C1_P">
                    In 2011, it was announced as a closed shareholders company. Pharmaoverseas has more than 40 branches include 4 main stores covering all
                    territories of Egypt with more than 81,000 m2 warehousing areas. Good Storage Practice "GSP" is applied in all warehouses. Various KPIs are
                    in place to monitor personnel efficiency as well as services rendered to customers to maintain quality standards. Code of Conduct is the
                    guide in every day to day activities.
                  </p>
                  <p className="CaseStudies_P4_C1_P">
                    It fulfills the obligations towards Pharmaoverseas suppliers, governmental bodies, society, and customers.
                  </p>
                </div>
                <div>
                  <h3 className="CaseStudies_P4_C1_H3">Executive Summary:</h3>
                </div>
                <div className="spaceLine"></div>
                <div>
                  <p className="CaseStudies_P4_C1_P">
                    In the face of tremendous challenges in Egypt and as the country’s largest pharmaceutical distributer , Pharmaoverseas sought to build
                    business resilience and promote economic growth by helping safeguard the continuous supply of essential goods.
                  </p>
                  <p className="CaseStudies_P4_C1_P">
                    The goal was to digitalize its operations using industry-leading cloud solutions, helping support its planned expansion and provide the
                    flexibility and scale to grow its operations while enabling visibility and data insights.
                  </p>
                </div>
                <div className="CaseStudies_P4_C1_Logo">
                  <LazyMedia type="image" src={MainLogo} alt="MainLogo" className="CaseMainLogo" />
                  <LazyMedia type="image" src={AwsLogo} alt="MainLogo" className="CaseAwsLogo" />

                  <LazyMedia type="image" src={SapLogo} alt="SapLogo" className="CaseSapLogo" />
                </div>
              </div>
              <div className="CaseStudies_P4_C1_Img">
                <LazyMedia type="image" src={CaseStudies_P3_S1} alt="" className="CaseStudies_P_P3_S1Web" />
              </div>
            </div>
            {/* second part */}
            <div className="Case_P4_C1_Container">
              <div className="Case_P4_C1_Text">
                <div>
                  <h3 className="CaseStudies_P4_C1_H3">Before: Challenges and Opportunities</h3>
                </div>
                <div className="spaceLine"></div>
                <div className="Case_P4_C1_Text_Ul">
                  {/* point list one */}
                  <div className="Case_P4_C1_Text_Ul_Main">
                    <h4>Before: Challenges and Opportunities</h4>
                    <ul className="Case_P4_C1_Text_Ul_Container">
                      <li>
                        Digitalize operations to build business resilience, leveraging new technologies to promote economic growth as well as Pharmaceutical
                        distribution and job security in Egypt
                      </li>
                      <li>Automate business processes to increase efficiencies and lower costs</li>
                      <li>Gain data visibility across the organization and improve reporting</li>
                      <li>Migrating SAP system workload from IBM Power Servers on premises datacenter to SAP RISE on AWS Cloud</li>
                      <li>Optimize the current system Database and enhance performance</li>
                    </ul>
                  </div>
                  {/* point list two */}
                  <div className="Case_P4_C1_Text_Ul_Main">
                    <h4>Target Architectural Diagram </h4>
                    <p className="Case_P4_C1_Right_P4_P1">
                      Phoenix will work to create the AWS Transit Gateway in PHARMAOVERSEAS AWS account and share it with the SAP managed AWS account.
                      Afterwards, SAP can attach the VPC for the managed SAP service to the AWS Transit Gateway and enable traffic flow through an entry in the
                      route table. With this setup keep control about traffic routing, because the AWS Transit Gateway resides in PHARMAOVERSEAS own account
                      where it can be managed
                    </p>
                  </div>
                  {/* point list three */}
                  <div className="Case_P4_C1_Text_Ul_Main">
                    <h4>After: Value-Driven Results</h4>

                    <ul className="Case_P4_C1_Text_Ul_Container">
                      <li>Digitally transformed operations for 2,500 users in 25 Egyptian provinces across 4 business entities</li>
                      <li>50% Improvement in system uptime and performance</li>
                      <li>100% Reduction in hardware support, maintenance, and upgrade costs</li>
                      <li>30% Reduction in total cost of IT ownership expected</li>
                      <li>20% Improvement in business processes using real-timedata and connected systems</li>
                      <li> 1.5 months to migrate to SAP on AWS</li>
                      <li>Enhanced business efficiency, visibility, and productivity while automating reporting and compliance</li>
                      <li> Reduced maintenance and total cost of ownership</li>
                      <li>Enabled the reinvestment of its server upgrade and maintenance budget to the business</li>
                      <li>
                        Readied the business for the future with scalable and flexible technology that allows agile operations based on data-driven decisions
                      </li>
                      <li>Improve security posture of workloads in the cloud by way of cloud native security tools/services</li>
                      <li>Meeting stringent RPO and RTO with multi-AZ Server deployment</li>
                    </ul>
                  </div>
                </div>
                <div className="CaseStudies_P4_C1_Logo">
                  <LazyMedia type="image" src={MainLogo} alt="MainLogo" className="CaseMainLogo" />
                  <LazyMedia type="image" src={AwsLogo} alt="MainLogo" className="CaseAwsLogo" />
                  <LazyMedia type="image" src={SapLogo} alt="SapLogo" className="CaseSapLogo" />
                </div>
              </div>
              <div className="CaseStudies_P4_C1_Right">
                <LazyMedia type="image" src={caseStudiesP4} alt="caseStudiesP4" className="caseStudiesP4Web" />
              </div>
            </div>
          </div>
        </div>
        {/* Fourth Part */}
        <div className="CaseStudiesMain_P2_S2">
          {/* box 1 */}
          <Link to="/HSA" className="CaseStudiesX_P2_Box_S3_A">
            <div className="CaseStudiesX_P2_Box">
              <div className="CaseStudiesX_P2_Box_S1">
                <h3 className="CaseStudiesX_P2_Box_S1_H3">HSA GROUP</h3>
                <p className="CaseStudiesX_P2_Box_S1_P">Building business resilience in challenging times on advanced ERA in the cloud.</p>
              </div>
              <div className="CaseStudiesX_P2_Box_S2">
                <LazyMedia type="image" src={casestudiesXP2One} alt="casestudiesXP2One" className="casestudiesXP2One" />
              </div>
              <div className="CaseStudiesX_P2_Box_S3">
                <button className="CaseStudiesX_P2_Box_S3_Button">
                  <p className="CaseStudiesX_P2_Box_S3_Button_P">Read full story</p>
                  <LazyMedia type="image" src={casestudiesXP2Arrow} alt="casestudiesXP2Arrow" className="casestudiesXP2Arrow" />
                </button>
              </div>
            </div>
          </Link>
          {/* box 2 */}
          <Link to="/EGIC" className="CaseStudiesX_P2_Box_S3_A">
            <div className="CaseStudiesX_P2_Box">
              <div className="CaseStudiesX_P2_Box_S1">
                <h3 className="CaseStudiesX_P2_Box_S1_H3">EGIC</h3>
                <p className="CaseStudiesX_P2_Box_S1_P">EGIC was established to introduce PP-R systems for the water supply in the Egyptian market</p>
              </div>
              <div className="CaseStudiesX_P2_Box_S2">
                <LazyMedia type="image" src={casestudiesXP2Two} alt="casestudiesXP2Two" className="casestudiesXP2One" />
              </div>
              <div className="CaseStudiesX_P2_Box_S3">
                <button className="CaseStudiesX_P2_Box_S3_Button">
                  <p className="CaseStudiesX_P2_Box_S3_Button_P">Read full story</p>
                  <LazyMedia type="image" src={casestudiesXP2Arrow} alt="casestudiesXP2Arrow" className="casestudiesXP2Arrow" />
                </button>
              </div>
            </div>
          </Link>
          {/* box 3 */}
          <Link to="/AmerGroup" className="CaseStudiesX_P2_Box_S3_A">
            <div className="CaseStudiesX_P2_Box">
              <div className="CaseStudiesX_P2_Box_S1">
                <h3 className="CaseStudiesX_P2_Box_S1_H3">Amer Group</h3>
                <p className="CaseStudiesX_P2_Box_S1_P">Amer Group is a leading real estate developer that offers mixed-use, family-oriented destinations.</p>
              </div>
              <div className="CaseStudiesX_P2_Box_S2">
                <LazyMedia type="image" src={AmerGroup} alt="casestudiesXP2One" className="casestudiesXP2One AmerGroup" />
              </div>
              <div className="CaseStudiesX_P2_Box_S3">
                <button className="CaseStudiesX_P2_Box_S3_Button">
                  <p className="CaseStudiesX_P2_Box_S3_Button_P">Read full story</p>
                  <LazyMedia type="image" src={casestudiesXP2Arrow} alt="casestudiesXP2Arrow" className="casestudiesXP2Arrow" />
                </button>
              </div>
            </div>
          </Link>
        </div>
        <div className="CaseStudiesMain_P2_S2">
          {/* box 5 */}
          <Link to="/Damietta" className="CaseStudiesX_P2_Box_S3_A">
            <div className="CaseStudiesX_P2_Box">
              <div className="CaseStudiesX_P2_Box_S1">
                <h3 className="CaseStudiesX_P2_Box_S1_H3">Damietta Alliance</h3>
                <p className="CaseStudiesX_P2_Box_S1_P">The Port of Damietta is one of the main ports in Egypt, close to the River Nile</p>
              </div>
              <div className="CaseStudiesX_P2_Box_S2">
                <LazyMedia type="image" src={Damietta} alt="casestudiesXP2One" className="casestudiesXP2One Damietta" />
              </div>
              <div className="CaseStudiesX_P2_Box_S3">
                <button className="CaseStudiesX_P2_Box_S3_Button">
                  <p className="CaseStudiesX_P2_Box_S3_Button_P">Read full story</p>
                  <LazyMedia type="image" src={casestudiesXP2Arrow} alt="casestudiesXP2Arrow" className="casestudiesXP2Arrow" />
                </button>
              </div>
            </div>
          </Link>
          {/* box 6 */}
          <Link to="/ADH" className="CaseStudiesX_P2_Box_S3_A">
            <div className="CaseStudiesX_P2_Box">
              <div className="CaseStudiesX_P2_Box_S1">
                <h3 className="CaseStudiesX_P2_Box_S1_H3 SLine">Arab Developers Holding</h3>
                <p className="CaseStudiesX_P2_Box_S1_P">Capturing the untapped potential and delivering healthy returns for clients</p>
              </div>
              <div className="CaseStudiesX_P2_Box_S2">
                <LazyMedia type="image" src={ADH} alt="casestudiesXP2One" className="casestudiesXP2One Damietta" />
              </div>
              <div className="CaseStudiesX_P2_Box_S3">
                <button className="CaseStudiesX_P2_Box_S3_Button">
                  <p className="CaseStudiesX_P2_Box_S3_Button_P">Read full story</p>
                  <LazyMedia type="image" src={casestudiesXP2Arrow} alt="casestudiesXP2Arrow" className="casestudiesXP2Arrow" />
                </button>
              </div>
            </div>
          </Link>
        </div>
        <div className="None"></div>
      </div>
    </>
  );
}

export default CaseStudyPharmaoverseasContent;
