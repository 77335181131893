import React from "react";
import "./caseStudy_Amer.css";
import { Link } from "react-router-dom";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import CaseStudies_P3_S1 from "../../assets/images/Egic-Pdf.jpg";
import SapLogo from "../../assets/svg/Sap_SapBlue.svg";
import AwsLogo from "../../assets/images/Aws_Logo_For_Case.jpg";
import MainLogo from "../../assets/svg/Logo.svg";
import caseStudiesP5 from "../../assets/images/caseStudiesP5_Amer.png";
import casestudiesXP2One from "../../assets/svg/CaseStudiesX_P2_ImgOne.svg";
import casestudiesXP2TwoAmer from "../../assets/images/amer.png";
import EGICgroup from "../../assets/svg/CaseStudiesX_P2_ImgTwo.svg";
import casestudiesXP2Three from "../../assets/svg/casestudiesXP2Three.svg";
import casestudiesXP2Arrow from "../../assets/svg/CaseStudiesX_P2_Arrow.svg";
import Damietta from "../../assets/images/damietta.png";
import ADH from "../../assets/images/ADH-logo.png";
import LazyMedia from "../../lazyLoad/lazyLoad";

function CaseStudyAmerContent() {
  return (
    <>
      <div className="caseStudyEGICContainer">
        {/* First Part */}
        <div className="CaseStudyEGICFirstPart">
          <div className="EGIC_P1_S1">
            <Link to="/case-Studies">
              <h3 className="EGIC_P1_S1_H3">Case Studies</h3>
            </Link>
            <KeyboardArrowLeftIcon className="KeyboardArrowLeftIcon" />
            <Link to="/AmerGroup">
              <h3 className="EGIC_P1_S1_H3">Amer Group</h3>
            </Link>
          </div>
          <div className="EGIC_P1_S2_Container">
            <div className="EGIC_P1_S2">
              <h1 className="EGIC_P1_H1">AMER GROUP</h1>

              <h3 className="EGIC_P1_H3">Amer Group is a leading real estate developer that offers mixed-use, family-oriented destinations.</h3>
              <p className="EGIC_P1_P">
                In 1991, Egyptian German Industrial Corporate – EGIC was established to introduce PP-R systems for the water supply in the Egyptian market, then
                expanded its entire production to produce and market all types of reliable plumbing and sanitary products.
              </p>
            </div>
          </div>
        </div>
        {/* Second Part */}
        {/* Second Part */}
        <div className="EGIC_P2_S1">
          <div>
            <LazyMedia type="image" src={casestudiesXP2TwoAmer} alt="casestudiesXP2Two" className="casestudiesXP2OneWeb AmerGroup" />
          </div>
          <div className="EGIC_P2_S1_Container">
            <h1 className="EGIC_P2_S1_H1">
              Their story began over 25 years ago when Mr. Mansour Amer founded Amer Group based on the core values of his family: “Character, Courage &
              Integrity”. Today, Amer Group continues to ensure commitment to those values, while raising the standards for innovation, outstanding design,
              quality and superior market value.
            </h1>
            <LazyMedia type="image" src={casestudiesXP2TwoAmer} alt="casestudiesXP2Two" className="AmerGroup casestudiesXP2OneAmerMobile" />
          </div>
        </div>
        {/* third part */}
        <div className="CaseStudies_FifthPart">
          <div className="CaseStudies_P5_Content1">
            {/* first part */}
            <div className="Case_P5_C1_Container">
              <div className="Case_P5_C1_Text_Amer">
                <div>
                  <h3 className="CaseStudies_P5_C1_H3">About AMER GROUP</h3>
                </div>
                <div className="spaceLine"></div>
                <div>
                  <p className="CaseStudies_P5_C1_P">Amer Group is a leading real estate developer that offers mixed-use, family-oriented destinations.</p>
                  <p className="CaseStudies_P5_C1_P">
                    Their story began over 25 years ago when Mr. Mansour Amer founded Amer Group based on the core values of his family: “Character, Courage &
                    Integrity”.
                  </p>
                  <p className="CaseStudies_P5_C1_P">
                    Today, Amer Group continues to ensure commitment to those values, while raising the standards for innovation, outstanding design, quality
                    and superior market value.
                  </p>
                  <p className="CaseStudies_P5_C1_P">
                    “Developing with value” is the motto of our group. Mr. Mansour & his team of real estate, restaurateur and hoteling professionals set out to
                    create new benchmarks in Egypt’s mixed-use, family-orientated resort destination market. We are always planning ahead for the future needs
                    of our clients while anticipating surrounding market conditions.
                  </p>
                </div>
                <div>
                  <h3 className="CaseStudies_P5_C1_H3">Executive Summary:</h3>
                </div>
                <div className="spaceLine"></div>
                <div>
                  <p className="CaseStudies_P5_C1_P">Customer requires to migrate their SAP operation applications from another cloud provider to AWS.</p>
                  <p className="CaseStudies_P5_C1_P">
                    Phoenix worked to migrate the customer landscape from another cloud provider to AWS and migrate Solution Manager server, Help desk server,
                    ADS server, and Windows Terminal server to AWS. Phoenix will use AWS MGN, AWS S3 CLI, and standard SAP tools for system migration.
                  </p>
                  <p className="CaseStudies_P5_C1_P">
                    Customer requires for professional hyperscaler hosting their SAP On-premises content server. Phoenix will work to build the customer
                    landscape over AWS, migrate, and build DMS/content Server to AWS.
                  </p>
                  <p className="CaseStudies_P5_C1_P">
                    Customer requires stable VPN solution. Phoenix worked to build the customer FortiGate server on AWS. Managing and securing the Firewall VPC
                    to ensure stable and secure VPN connections between AWS and on-premises sites.
                  </p>
                </div>
                {/* logo at the end of paper */}
                <div className="CaseStudies_P5_C1_Logo">
                  <LazyMedia type="image" src={MainLogo} alt="MainLogo" className="CaseMainLogo" />
                  <LazyMedia type="image" src={AwsLogo} alt="SapLogo" className="CaseAwsLogo" />
                  <LazyMedia type="image" src={SapLogo} alt="SapLogo" className="CaseSapLogo" />
                </div>
              </div>
              {/* image right */}
              <div className="CaseStudies_P5_C1_Img">
                <LazyMedia type="image" src={CaseStudies_P3_S1} alt="" className="CaseStudies_P5_C1_ImgWebAmer" />
              </div>
            </div>
            {/* second part */}
            <div className="Case_P5_C1_Container">
              <div className="Case_P5_C1_Text">
                <div>
                  <h3 className="CaseStudies_P5_C1_H3">Before: Challenges and Opportunities</h3>
                </div>
                <div className="spaceLine"></div>
                <div className="Case_P5_C1_Text_Ul">
                  {/* point list one */}
                  <div className="Case_P5_C1_Text_Ul_Main">
                    <h4>Before: Challenges and Opportunities</h4>
                    <ul className="Case_P5_C1_Text_Ul_Container">
                      <li>High-available and durable backup storage solution.</li>
                      <li>Stable servers’ backups and life policies.</li>
                      <li>Lower latency and servers’ stability.</li>
                      <li>Automate business processes to increase efficiency and lower costs.</li>
                      <li>Optimize the current system Database and enhance performance.</li>
                      <li>Enhancing the Security of the cloud environment. </li>
                    </ul>
                  </div>
                  {/* point list two */}
                  <div className="Case_P5_C1_Text_Ul_Main">
                    <h4>Target Architectural Diagram </h4>
                    <ul className="Case_P5_C1_Text_Ul_Container">
                      <li>Provisioning AWS customer VPC focusing on cost optimization, stable connections from on-premises sites and securing the network.</li>
                      <li>Migrating SAP Cloud Native Environment from customer environment to AWS using AWS MGN and S3 CLI.</li>
                      <li>
                        Launching and Configuring FortiGate Firewall to Secure the environment and to connect the AWS VPC to Customer on-premises sites using
                        EC2 and AWS Market Place.
                      </li>
                      <li>Hosting SAP Router server as a backup solution for the VPN connections.</li>
                      <li>
                        Creating Amazon S3 bucket for storing backups and using least privilege principle for giving the right permissions to mount as
                        filesystem to all required severs.
                      </li>
                      <li>Migrate DMS server from on-premises to AWS Cloud.</li>
                      <li>Managing solid EBS life cycle policies and CloudWatch alarms for specific workload thresholds.</li>
                    </ul>
                  </div>
                  {/* point list three */}
                  <div className="Case_P5_C1_Text_Ul_Main">
                    <h4>After: Value-Driven Results</h4>
                    <ul className="Case_P5_C1_Text_Ul_Container">
                      <li>Enhanced business efficiency, visibility, and productivity while automating reporting and compliance.</li>
                      <li>Enhanced data durability for SAP backups to 11 9's Durability by using AWS S3.</li>
                      <li>Reduced maintenance and total cost of ownership.</li>
                      <li>Enabled the reinvestment of its server upgrade and maintenance budget to the business.</li>
                      <li>
                        Readied the business for the future with scalable and flexible technology that allows agile operations based on data-driven decisions.
                      </li>
                      <li>Improved snapshots life policy to save cost and minimize RPO.</li>
                      <li>Seamless experience with alarms and notification for specific metrics.</li>
                    </ul>
                  </div>
                </div>
                <div className="CaseStudies_P5_C1_Logo">
                  <LazyMedia type="image" src={MainLogo} alt="MainLogo" className="CaseMainLogo" />
                  <LazyMedia type="image" src={AwsLogo} alt="SapLogo" className="CaseAwsLogo" />
                  <LazyMedia type="image" src={SapLogo} alt="SapLogo" className="CaseSapLogo" />
                </div>
              </div>
              <div className="CaseStudies_P5_C1_Right">
                <LazyMedia type="image" src={caseStudiesP5} alt="caseStudiesP5" className="CaseStudies_P5_C1_Right_ImgWeb" />
              </div>
            </div>
          </div>
        </div>
        {/* third Part */}
        <div className="CaseStudiesMain_P2_S2">
          {/* box 1 */}
          <Link to="/HSA" className="CaseStudiesX_P2_Box_S3_A">
            <div className="CaseStudiesX_P2_Box">
              <div className="CaseStudiesX_P2_Box_S1">
                <h3 className="CaseStudiesX_P2_Box_S1_H3">HSA GROUP</h3>
                <p className="CaseStudiesX_P2_Box_S1_P">Building business resilience in challenging times on advanced ERA in the cloud.</p>
              </div>
              <div className="CaseStudiesX_P2_Box_S2">
                <LazyMedia type="image" src={casestudiesXP2One} alt="casestudiesXP2One" className="casestudiesXP2One" />
              </div>
              <div className="CaseStudiesX_P2_Box_S3">
                <button className="CaseStudiesX_P2_Box_S3_Button">
                  <p className="CaseStudiesX_P2_Box_S3_Button_P">Read full story</p>
                  <LazyMedia type="image" src={casestudiesXP2Arrow} alt="casestudiesXP2Arrow" className="casestudiesXP2Arrow" />
                </button>
              </div>
            </div>
          </Link>
          {/* box 2 */}
          <Link to="/Damietta" className="CaseStudiesX_P2_Box_S3_A">
            <div className="CaseStudiesX_P2_Box">
              <div className="CaseStudiesX_P2_Box_S1">
                <h3 className="CaseStudiesX_P2_Box_S1_H3">Damietta Alliance</h3>
                <p className="CaseStudiesX_P2_Box_S1_P">The Port of Damietta is one of the main ports in Egypt, close to the River Nile</p>
              </div>
              <div className="CaseStudiesX_P2_Box_S2">
                <LazyMedia type="image" src={Damietta} alt="casestudiesXP2One" className="casestudiesXP2One Damietta" />
              </div>
              <div className="CaseStudiesX_P2_Box_S3">
                <button className="CaseStudiesX_P2_Box_S3_Button">
                  <p className="CaseStudiesX_P2_Box_S3_Button_P">Read full story</p>
                  <LazyMedia type="image" src={casestudiesXP2Arrow} alt="casestudiesXP2Arrow" className="casestudiesXP2Arrow" />
                </button>
              </div>
            </div>
          </Link>
          {/* box 3 */}
          <Link to="/Pharmaoverseas" className="CaseStudiesX_P2_Box_S3_A">
            <div className="CaseStudiesX_P2_Box">
              <div className="CaseStudiesX_P2_Box_S1">
                <h3 className="CaseStudiesX_P2_Box_S1_H3">Pharmaoverseas</h3>
                <p className="CaseStudiesX_P2_Box_S1_P">Pharmaoverseas suppliers, governmental bodies, society, and customers</p>
              </div>
              <div className="CaseStudiesX_P2_Box_S2">
                <LazyMedia type="image" src={casestudiesXP2Three} alt="casestudiesXP2Three" className="casestudiesXP2One" />
              </div>
              <div className="CaseStudiesX_P2_Box_S3">
                <button className="CaseStudiesX_P2_Box_S3_Button">
                  <p className="CaseStudiesX_P2_Box_S3_Button_P">Read full story</p>
                  <LazyMedia type="image" src={casestudiesXP2Arrow} alt="casestudiesXP2Arrow" className="casestudiesXP2Arrow" />
                </button>
              </div>
            </div>
          </Link>
          {/* box 4 */}
        </div>
        <div className="CaseStudiesMain_P2_S2">
          {/* box 4 */}
          <Link to="/EGIC" className="CaseStudiesX_P2_Box_S3_A">
            <div className="CaseStudiesX_P2_Box">
              <div className="CaseStudiesX_P2_Box_S1">
                <h3 className="CaseStudiesX_P2_Box_S1_H3">EGIC</h3>
                <p className="CaseStudiesX_P2_Box_S1_P">EGIC was established to introduce PP-R systems for the water supply in the Egyptian market</p>
              </div>
              <div className="CaseStudiesX_P2_Box_S2">
                <LazyMedia type="image" src={EGICgroup} alt="casestudiesXP2Two" className="casestudiesXP2One" />
              </div>
              <div className="CaseStudiesX_P2_Box_S3">
                <button className="CaseStudiesX_P2_Box_S3_Button">
                  <p className="CaseStudiesX_P2_Box_S3_Button_P">Read full story</p>
                  <LazyMedia type="image" src={casestudiesXP2Arrow} alt="casestudiesXP2Arrow" className="casestudiesXP2Arrow" />
                </button>
              </div>
            </div>
          </Link>
          {/* box 5 */}
          <Link to="/ADH" className="CaseStudiesX_P2_Box_S3_A">
            <div className="CaseStudiesX_P2_Box">
              <div className="CaseStudiesX_P2_Box_S1">
                <h3 className="CaseStudiesX_P2_Box_S1_H3 SLine">Arab Developers Holding</h3>
                <p className="CaseStudiesX_P2_Box_S1_P">Capturing the untapped potential and delivering healthy returns for clients</p>
              </div>
              <div className="CaseStudiesX_P2_Box_S2">
                <LazyMedia type="image" src={ADH} alt="casestudiesXP2One" className="casestudiesXP2One Damietta" />
              </div>
              <div className="CaseStudiesX_P2_Box_S3">
                <button className="CaseStudiesX_P2_Box_S3_Button">
                  <p className="CaseStudiesX_P2_Box_S3_Button_P">Read full story</p>
                  <LazyMedia type="image" src={casestudiesXP2Arrow} alt="casestudiesXP2Arrow" className="casestudiesXP2Arrow" />
                </button>
              </div>
            </div>
          </Link>
        </div>
        <div className="None"></div>
      </div>
    </>
  );
}

export default CaseStudyAmerContent;
