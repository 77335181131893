import React from "react";
import "./solutionsSubmenuMobile.css";
import { Link } from "react-router-dom";

function SolutionsSubmenuMobile() {
  return (
    <div className="submenuMobile_Solutions_main">
      <Link to="/RealEstate" className="submenuMobile_Solutions_link">
        Real Estate
      </Link>
      <Link to="/Tender" className="submenuMobile_Solutions_link">
        Tender
      </Link>
    </div>
  );
}

export default SolutionsSubmenuMobile;
