import React from "react";
import "./AccordionTender.css";
import { ReactComponent as Plus } from "../../../assets/svg/plus.svg";
import { ReactComponent as Minus } from "../../../assets/svg/minus.svg";

const AccordionTenderItem = ({ title, children, isOpen, onAccordionClick, identifier }) => {
  const handleClick = () => {
    if (isOpen) {
      onAccordionClick(null); // If it's already open, close it
    } else {
      onAccordionClick(identifier); // Otherwise, open the clicked one
    }
  };
  return (
    <div className="accordionTender-item">
      <div className="accordionTender-header" onClick={handleClick}>
        <span className="accordionTender-title">{title}</span>
        <span className="accordionTender-icon">{isOpen ? <Minus /> : <Plus className="Plus" />}</span>
      </div>
      {isOpen && <div className="accordionTender-content">{children}</div>}
    </div>
  );
};

function AccordionSapLeftTender({ openedAccordion, setOpenedAccordion }) {
  return (
    <div className="accordionTender">
      <AccordionTenderItem
        title="Auto Supplier Registration Process"
        identifier="left-1"
        isOpen={openedAccordion === "left-1"}
        onAccordionClick={setOpenedAccordion}
      >
        <div>
          <li>Seamlessly register suppliers online through our e-Procurement Portal.</li>
          <li>Automatically verify, evaluate, and approve supplier qualifications.</li>
          <li>Certify suppliers and create their profiles in your ERP master data.</li>
          <li>Issue Supplier IDs and promptly notify suppliers with their login details.</li>
        </div>
      </AccordionTenderItem>

      <div className="accordionTender_second">
        <AccordionTenderItem title="Procurement Processes" identifier="left-2" isOpen={openedAccordion === "left-2"} onAccordionClick={setOpenedAccordion}>
          <li> Identify procurement requirements accurately.</li>
          <li> Collaborate with your partners, staff, and suppliers.</li>
          <li> Refine your business case and budget effectively.</li>
          <li> Choose the ideal procurement route based on your needs.</li>
          <li> Easily manage tender notices and specifications.</li>
          <li> Invite submissions securely.</li>
          <li> Apply streamlined tendering processes.</li>
          <li> Receive and automatically evaluate bids, both financially and technically.</li>
          <li> Efficiently award contracts.</li>
        </AccordionTenderItem>
      </div>

      <div className="accordionTender_second">
        <AccordionTenderItem title="Delivery Processes" identifier="left-3" isOpen={openedAccordion === "left-3"} onAccordionClick={setOpenedAccordion}>
          <li>Collaborate with chosen supplier(s) seamlessly.</li>
          <li>Efficiently manage contracts and handle change requests.</li>
          <li>Continuously monitor supplier performance.</li>
          <li>Clearly define renewal requirements and ensure the smooth operation of your procurement.</li>
        </AccordionTenderItem>
      </div>
    </div>
  );
}

export default AccordionSapLeftTender;
