import React from "react";
import "./partnersSubmenuMobile.css";
import { Link } from "react-router-dom";

function PartnersSubmenuMobile() {
  return (
    <div className="submenuMobile_Partners_main">
      <Link to="/sap-partnership" className="submenuMobile_Partners_link">
        SAP
      </Link>
      <Link to="/aws-partnership" className="submenuMobile_Partners_link">
        AWS
      </Link>
      <Link to="/azure-partnership" className="submenuMobile_Partners_link">
        Microsoft Azure
      </Link>
      <Link to="/Trend-Micro" className="submenuMobile_Partners_link">
        Trend Micro
      </Link>
    </div>
  );
}

export default PartnersSubmenuMobile;
