import React from "react";
import "./Sap_SuccessFactors.css";
import { Link } from "react-router-dom";
import SuccessFactorsP2S1 from "../../assets/svg/SuccessFactors_P2_S1.svg";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import SuccessFactorsP4S1 from "../../assets/images/SuccessFactors_P4_S1.jpg";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import SapBannerVideo from "../../assets/videos/SapBannerVideo.mp4";
import LazyMedia from "../../lazyLoad/lazyLoad";

function SapSuccessFactorsContent() {
  return (
    <>
      <div className="SapSuccessFactorsContainer">
        {/* First Part */}
        <div className="SuccessFactorsFirstPart">
          <LazyMedia type="video" src={SapBannerVideo} className="Sap_VideoBackground" />
          <div className="Success_P1_S1">
            <Link to="/sap-partnership">
              <h3 className="Success_P1_S1_H3">SAP</h3>
            </Link>
            <KeyboardArrowLeftIcon className="KeyboardArrowLeftIcon" />
            <Link to="/sap-SuccessFactors">
              <h3 className="Success_P1_S1_H3">SAP SuccessFactors</h3>
            </Link>
          </div>
          <div className="Success_P1_S2">
            <h1 className="Success_P1_H1">SAP SuccessFactors</h1>
            <h3 className="Success_P1_H3">HCM Cloud Software Solution</h3>
          </div>
        </div>
        {/* Second Part */}
        <div className="SuccessFactorsSecondPart">
          <div className="Success_P2_S1">
            <h1 className="Success_P2_S1_H1">Placing People at the Heart of Your Business</h1>
            <LazyMedia type="image" src={SuccessFactorsP2S1} alt="SuccessFactorsP2S1" className="SuccessFactorsP2S1Mobile" />
            <p className="Success_P2_S1_P">
              Work, the people, and the places where we work keep changing. Businesses, technology, and what people expect are always evolving. HR leaders and
              top decision-makers understand that the way we work also needs to change. We need to be flexible and make sure the changes bring lasting benefits.
            </p>
          </div>
          <LazyMedia type="image" src={SuccessFactorsP2S1} alt="SuccessFactorsP2S1" className="SuccessFactorsP2S1Web" />
        </div>
        {/* Third Part */}
        <div className="SuccessFactorsThirdPart">
          <h1 className="Success_P3_S1_H1">Why Choose SAP SuccessFactors?</h1>
          <p className="Success_P3_S1_P">
            Phoenix Consulting is your gateway to a future-ready HR solution. When you choose SAP SuccessFactors, you're opting for
          </p>
          <div className="Success_P3_S2">
            {/* Box 1 */}
            <div className="Success_P3_S2_Box">
              <h3 className="Success_P3_S2_Box_H3">End-to-End HCM</h3>
              <p className="Success_P3_S2_Box_P">
                Cover every aspect of HR management, from core HR to talent management, in one platform. With SuccessFactors, you can integrate all HR functions
                seamlessly, enabling a holistic approach to managing your workforce.
              </p>
            </div>
            {/* Box 2 */}
            <div className="Success_P3_S2_Box">
              <h3 className="Success_P3_S2_Box_H3">User-Friendly Interface</h3>
              <p className="Success_P3_S2_Box_P">
                Experience an intuitive, easy-to-navigate interface that encourages employee engagement. SuccessFactors provides a modern, user-friendly
                platform that promotes self-service, empowering employees to manage their HR-related tasks efficiently.
              </p>
            </div>
            {/* Box 3 */}
            <div className="Success_P3_S2_Box">
              <h3 className="Success_P3_S2_Box_H3">Cloud Convenience</h3>
              <p className="Success_P3_S2_Box_P">
                Access your HR data securely from anywhere, anytime, with the power of the cloud. SuccessFactors leverages the cloud's scalability and
                flexibility, ensuring you can adapt to changing HR needs and deliver an exceptional experience to your employees.
              </p>
            </div>
            {/* Box 4 */}
            <div className="Success_P3_S2_Box">
              <h3 className="Success_P3_S2_Box_H3">Compliance and Security</h3>
              <p className="Success_P3_S2_Box_P">
                Stay compliant with ever-evolving regulations and industry standards, knowing your data is secure. With SuccessFactors, you benefit from robust
                security features and compliance updates that protect your HR data and your organization's reputation.
              </p>
            </div>
          </div>
        </div>
        {/* Fourth Part */}
        <div className="SuccessFactorsFourthPart">
          <div className="Success_P4_S1">
            <div className="Success_P4_S1_Text">
              <h1 className="Success_P4_S1_Text_H1">Improving decision-making with SuccessFactors</h1>
              <LazyMedia type="image" src={SuccessFactorsP4S1} alt="SuccessFactorsP4S1" className="SuccessFactorsP4S1Mobile" />
              <p className="Success_P4_S1_Text_P">
                SuccessFactors stands out by offering top-tier insight and analytics to Executives, facilitating faster and more informed decision-making. This
                software empowers organizations to make sure they have the right individuals, with the precise skills, assigned to the correct roles.
              </p>
            </div>
            <LazyMedia type="image" src={SuccessFactorsP4S1} alt="SuccessFactorsP4S1" className="SuccessFactorsP4S1Web" />
          </div>
          <div className="Success_P4_S2">
            <p className="Success_P4_S2_P">
              “ SAP SuccessFactors HCM Software leads the SAAS industry with a track record of success and ongoing innovations, creating a competitive
              advantage. Its HCM Suite offers a secure, scalable foundation that facilitates quick implementation, rapid outcomes, and continual advancements.
              This results in cost-effective ownership compared to alternative solutions. ”
            </p>
          </div>
        </div>
        {/* Fifth Part */}
        <div className="SuccessFactorsFifthPart">
          <div className="Success_P5_S1">
            <h1 className="Success_P5_S1_H1">Our SAP SuccessFactors Services</h1>
          </div>
          <div className="Success_P5_S2">
            {/* Box 1 */}
            <div className="Success_P5_S2_Box">
              <h3 className="Success_P5_S2_Box_H3">Core HR</h3>
              <p className="Success_P5_S2_Box_P">
                Achieve operational excellence with streamlined HR processes. SuccessFactors Core HR provides a centralized system for managing your HR data.
                From organizational structures to employee records, our Core HR service ensures data accuracy and accessibility. We'll help you implement Core
                HR to centralize your HR data, eliminate data redundancies, and ensure smooth data access across your organization.
              </p>
            </div>
            {/* Box 2 */}
            <div className="Success_P5_S2_Box">
              <h3 className="Success_P5_S2_Box_H3">Talent Management</h3>
              <p className="Success_P5_S2_Box_P">
                Identify, nurture, and retain top talent. SuccessFactors Talent Management empowers you to attract, develop, and maintain a high-performing
                workforce. Maximize your recruitment efforts, performance management, and employee development. Our talent management solutions will help you
                define your talent strategy, align employee goals with business objectives, and create personalized development plans.
              </p>
            </div>
            {/* Box 3 */}
            <div className="Success_P5_S2_Box">
              <h3 className="Success_P5_S2_Box_H3">HR Analytics</h3>
              <p className="Success_P5_S2_Box_P">
                Make data-driven decisions. SuccessFactors HR Analytics offers insights into your workforce, helping you optimize HR strategies. Monitor key
                metrics and trends to enhance your HR operations. Our HR analytics services will guide you through setting up a robust data analytics framework,
                allowing you to access essential HR insights to inform your strategic decisions.
              </p>
            </div>
            {/* Box 4 */}
            <div className="Success_P5_S2_Box">
              <h3 className="Success_P5_S2_Box_H3">Social Collaboration</h3>
              <p className="Success_P5_S2_Box_P">
                Foster a culture of collaboration. SuccessFactors Social Collaboration tools facilitate communication and knowledge sharing among your
                employees. Encourage teamwork and improve productivity with our collaboration services. We'll help you implement social collaboration features
                to enhance communication, knowledge sharing, and collaboration within your organization, ultimately boosting productivity and innovation.
              </p>
            </div>
          </div>
        </div>
        {/* Sixth Part */}
        <div className="SuccessFactorsSixthPart">
          <div className="Success_P6_S1">
            <h1 className="Success_P6_S1_H1">Value Proposition</h1>
          </div>
          <div className="Success_P6_S2">
            {/* Box 1 */}
            <div className="Success_P6_S2_Box">
              <h3 className="Success_P6_S2_Box_H3">Collaboration</h3>
              <p className="Success_P6_S2_Box_P">
                Our solution fosters seamless two-way communication between employees and the company, streamlining HR processes for enhanced efficiency.
              </p>
            </div>
            {/* Box 2 */}
            <div className="Success_P6_S2_Box">
              <h3 className="Success_P6_S2_Box_H3">Mobility</h3>
              <p className="Success_P6_S2_Box_P">
                Experience full mobility with easy access through iOS and Android platforms, ensuring you stay connected on the go.
              </p>
            </div>
            {/* Box 3 */}
            <div className="Success_P6_S2_Box">
              <h3 className="Success_P6_S2_Box_H3">Information</h3>
              <p className="Success_P6_S2_Box_P">
                We empower HR to evolve into a knowledge provider, offering integrated real-time analytics with precise authorization controls and role-based
                functionalities.
              </p>
            </div>
            {/* Box 4 */}
            <div className="Success_P6_S2_Box">
              <h3 className="Success_P6_S2_Box_H3">User Experience</h3>
              <p className="Success_P6_S2_Box_P">
                Our solution boasts an intuitive and user-friendly interface, customizable to match your organization's corporate image, thanks to its
                cloud-based human resources management software.
              </p>
            </div>
            {/* Box 5 */}
            <div className="Success_P6_S2_Box">
              <h3 className="Success_P6_S2_Box_H3">Travel Optimization</h3>
              <p className="Success_P6_S2_Box_P">
                Enhance productivity by allowing teams to focus on value-added tasks while automating administrative work, which can be efficiently managed by
                supervisors when necessary.
              </p>
            </div>
            {/* Box 6 */}
            <div className="Success_P6_S2_Box">
              <h3 className="Success_P6_S2_Box_H3">Integration with Your Systems</h3>
              <p className="Success_P6_S2_Box_P">
                Our system seamlessly integrates with your current SAP or non-SAP information systems, enabling data incorporation and sharing from third-party
                systems, amplifying your HR management capabilities.
              </p>
            </div>
          </div>
        </div>
        {/* Seventh Part */}
        <div className="SuccessFactorsSeventhPart">
          <div className="Success_P7_S1">
            <h1 className="Success_P7_S1_H1">Discover Other SAP Solutions</h1>
          </div>
          <div className="Success_P7_S2">
            {/* button 1 */}
            <Link to="/S/4Hana">
              <button className="Success_P7_S2_button">
                <h3>SAP S/4HANA</h3>
                <KeyboardArrowRightIcon />
              </button>
            </Link>
            {/* button 2 */}
            <Link to="/sap-ariba">
              <button className="Success_P7_S2_button">
                <h3>SAP Ariba</h3>
                <KeyboardArrowRightIcon />
              </button>
            </Link>
            {/* button 3 */}
            <Link to="/sap-rise">
              <button className="Success_P7_S2_button">
                <h3>Rise With SAP</h3>
                <KeyboardArrowRightIcon />
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default SapSuccessFactorsContent;
