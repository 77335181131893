import React from "react";
import "./moreSubmenu.css";
import { ReactComponent as ArrowMenu } from "../../../assets/svg/arrow-right-submenu.svg";
import { Link } from "react-router-dom";

function MoreSubmenu() {
  return (
    <div className="submenu_moreMain">
      <div className="submenu_contain_more">
        <div className="submenu_more_contain">
          <Link to="/clients" className="submenu_more_link">
            Clients
            <ArrowMenu />
          </Link>
          <Link to="/careers" className="submenu_more_link">
            Careers
            <ArrowMenu />
          </Link>
          <Link to="/case-Studies" className="submenu_more_link">
            Case Studies
            <ArrowMenu />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default MoreSubmenu;
