import React from "react";
import "./servicesSubmenuMobile.css";
import { Link } from "react-router-dom";

function ServicesSubmenuMobile() {
  return (
    <div className="submenuMobile_Services_main">
      <Link to="/devops" className="submenuMobile_Services_link">
        DevOps
      </Link>
      <Link to="/DigitalMarketing" className="submenuMobile_Services_link">
        Digital Marketing
      </Link>
      <Link to="/S/4hana" className="submenuMobile_Services_link">
        SAP 4/HANA
      </Link>
      <Link to="/sap-SuccessFactors" className="submenuMobile_Services_link">
        SAP SuccessFactors
      </Link>
      <Link to="/sap-ariba" className="submenuMobile_Services_link">
        SAP Ariba
      </Link>
      <Link to="/Sap-Rise" className="submenuMobile_Services_link">
        RISE with SAP
      </Link>
    </div>
  );
}

export default ServicesSubmenuMobile;
