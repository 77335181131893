import React, { useState, useRef } from "react";
import "./header-white.css";
import { ReactComponent as Logo } from "../../assets/svg/Logo.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Link } from "react-router-dom";
import ServicesSubmenu from "../submenu/Services/servicesSubmenu";
import IndustriesSubmenu from "../submenu/Industries/industriesSubmenu";
import SolutionsSubmenu from "../submenu/Solutions/solutionsSubmenu";
import PartnersSubmenu from "../submenu/Partners/partnersSubmenu";
import MoreSubmenu from "../submenu/More/moreSubmenu";
import useOutsideClick from "./useOutSideCLick";

function Header() {
  const [showSubmenu, setShowSubmenu] = useState(false);
  const [submenuType, setSubmenuType] = useState(null);
  const submenuRef = useRef();

  const closeSubmenu = () => {
    setShowSubmenu(false);
  };

  useOutsideClick(submenuRef, () => {
    if (showSubmenu) {
      closeSubmenu();
    }
  });

  const toggleSubmenu = (event, type) => {
    event.preventDefault();
    event.stopPropagation();

    setShowSubmenu((prevShowSubmenu) => {
      if (submenuType === type) {
        if (prevShowSubmenu) {
          setSubmenuType(null);
          return false;
        } else {
          return true;
        }
      } else {
        setSubmenuType(type);
        return true;
      }
    });
  };

  return (
    <>
      <div className="header_white">
        <div className="header_logo">
          <Link to="/">
            <Logo />
          </Link>
        </div>
        <div className="header_nav_white">
          <div className="header_nav_white_a">
            <Link to="/">
              <p className="header_nav_white_p">Home</p>
            </Link>
          </div>
          <div className="header_nav_white_a">
            <Link to="/about-us">
              <p className="header_nav_white_p">About</p>
            </Link>
          </div>
          <div className="header_nav_white_a">
            <a onMouseDown={(e) => toggleSubmenu(e, "services")} data-type="services">
              <p className="header_nav_white_p">Services</p>
              <KeyboardArrowDownIcon className="header_nav_icon" />
            </a>
          </div>
          <div className="header_nav_white_a">
            <a onMouseDown={(e) => toggleSubmenu(e, "industries")} data-type="industries">
              <p className="header_nav_white_p">Industries</p>
              <KeyboardArrowDownIcon className="header_nav_icon" />
            </a>
          </div>
          <div className="header_nav_white_a">
            <a onMouseDown={(e) => toggleSubmenu(e, "solutions")} data-type="solutions">
              <p className="header_nav_white_p">Products</p>
              <KeyboardArrowDownIcon className="header_nav_icon" />
            </a>
          </div>
          <div className="header_nav_white_a">
            <a onMouseDown={(e) => toggleSubmenu(e, "partners")} data-type="partners">
              <p className="header_nav_white_p">Partners</p>
              <KeyboardArrowDownIcon className="header_nav_icon" />
            </a>
          </div>
          <div className="header_nav_white_a">
            <a onMouseDown={(e) => toggleSubmenu(e, "more")} data-type="more">
              <p className="header_nav_white_p">More</p>
              <KeyboardArrowDownIcon className="header_nav_icon" />
            </a>
          </div>
        </div>
        <div className="header_button_white">
          <Link to="/contact-us">
            <p className="header_button_white_p">Contact us</p>
          </Link>
        </div>
      </div>

      {showSubmenu && (
        <div ref={submenuRef}>
          {submenuType === "services" && <ServicesSubmenu />}
          {submenuType === "industries" && <IndustriesSubmenu />}
          {submenuType === "solutions" && <SolutionsSubmenu />}
          {submenuType === "partners" && <PartnersSubmenu />}
          {submenuType === "more" && <MoreSubmenu />}
        </div>
      )}
    </>
  );
}
export default Header;
