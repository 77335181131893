import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./sap.css";
import SapBanner from "../../assets/videos/Partners_Banner.MP4";
import SapStar from "../../assets/svg/SapStar.svg";
import SapBlue from "../../assets/images/Home_P4_Sap.png";
import SapPlus from "../../assets/svg/Sap_plus.svg";
import SapNumberOne from "../../assets/svg/Sap+55.svg";
import SapNumberTwo from "../../assets/svg/Sap+20.svg";
import SapSpace from "../../assets/svg/Sap_Arrow.svg";
import SapImgP3Img from "../../assets/images/SapImgP3.jpg";
import SectionSpace from "../../assets/svg/careers-space.svg";
import SapRectangle from "../../assets/svg/Sap_Rectangle.svg";
import SapArrowRight from "../../assets/svg/SaP_arrow_button.svg";
import SapACC from "../../components/hooks/Accordion_Item/AccordionSapMain";
import SapACCMobile from "../../components/hooks/Accordion_Item/AccordionSapMainMobile";
import LazyMedia from "../../lazyLoad/lazyLoad";

function SapContent() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 475);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 475);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <>
      <div className="Sap_container">
        {/* Sap_FirstPart */}
        <div className="Sap_FirstPart">
          <LazyMedia type="video" src={SapBanner} className="Partners_VideoBackground" />
          <LazyMedia type="image" src={SapStar} alt="SapStar" className="SapStarMobile" />
          <div className="Sap_P1_P1">
            <h1 className="Sap_P1_P1_H1">Phoenix</h1>
            <LazyMedia type="image" src={SapPlus} alt="SapPlus" className="SapMain_Plus" />
            <LazyMedia type="image" src={SapBlue} alt="SapMain_Blue" className="SapMain_Blue" />
          </div>
          <h1 className="Sap_FirstPart_H1">Intelligent Decision-Making on the Go</h1>
          <p className="Sap_FirstPart_P">
            Phoenix operates a specialized business division focused on providing extensive services within the SAP ecosystem. This encompasses consulting,
            application and infrastructure implementation and maintenance, as well as software sales."
          </p>
        </div>
        {/* end Sap_FirstPart */}

        {/* Sap_SecondPart */}
        <div className="Sap_SecondPart">
          <div className="Sap_P1_S1">
            <div className="Sap_P1_S1_Main">
              <LazyMedia type="image" src={SapNumberOne} alt="SapNumberOne" />
              <h1 className="Sap_P1_S1_Main_H1">SAP Delivery Projects</h1>
            </div>
            <LazyMedia type="image" src={SapSpace} alt="SapSpace" className="SpaceHidden" />
            <div className="Sap_P1_S1_Main">
              <LazyMedia type="image" src={SapNumberTwo} alt="SapNumberTwo" />
              <h1 className="Sap_P1_S1_Main_H1">years of experience</h1>
            </div>
          </div>
          <div className="Sap_P1_S2">
            Your digital transformation efforts can be expedited and simplified with the right expertise and support from SAP solutions.
          </div>
        </div>
        {/* end Sap_SecondPart */}

        {/* Sap_ThirdPart */}
        <div className="Sap_ThirdPart">
          <div className="Sap_P3_S1">
            <h1 className="Sap_P3_S1_H1">Beyond your expectations</h1>
            <p className="Sap_P3_S1_P">
              Our main objective is to enable businesses to harness the power of Digital Transformation. This approach creates agile, cloud-ready enterprises
              that swiftly adapt to change. It encompasses key attributes like agility, resilience, efficiency, and a commitment to innovation. These qualities
              are essential for companies looking to expand beyond boundaries, foster cross-border collaboration, seize fresh value opportunities, exceed
              stakeholder expectations, and achieve substantial, impactful growth.
            </p>
          </div>
          {/* <SapImgP3 className="SapImgP3" /> */}
          <LazyMedia type="image" src={SapImgP3Img} alt="SapImgP3Img" className="SapImgP3Img" />
        </div>
        {/* end Sap_ThirdPart */}

        <div className="SectionSpace">
          <LazyMedia type="image" src={SectionSpace} alt="SectionSpace" />
        </div>
        {/* Sap_PartFour */}
        <div className="Sap_PartFour">
          <h1 className="Sap_PartFour_h1">OUR SOLUTIONS</h1>
          <div className="Sap_P4_S1_container">
            {/* Part 1 */}
            <div className="Sap_P4_S1_Main">
              <div className="Sap_P4_S1_Tag">
                <LazyMedia type="image" src={SapRectangle} alt="SapRectangle" />
                SAP 4/HANA
              </div>
              <p className="Sap_P4_S1_P">Are you prepared to safeguard the future of your business?</p>
              {/* hover content 4/hana */}

              <Link to="/S/4hana" className="hover-content">
                <div className="hover-content-Main">
                  <p className="hover_content_Main_P">
                    Access the SAP Transformation Case to find out how SAP S/4HANA can improve your business in various ways.
                  </p>
                  <Link to="/S/4hana" className="hover-content-button">
                    <button>
                      explore <LazyMedia type="image" src={SapArrowRight} alt="SapArrowRight" className="SapArrowRightButton" />
                    </button>
                  </Link>
                </div>
              </Link>
              {/* end hover content 4/hana */}
            </div>
            {/* Part 2 */}
            <div className="Sap_P4_S1_Main">
              <div className="Sap_P4_S1_Tag">
                <LazyMedia type="image" src={SapRectangle} alt="SapRectangle" />
                SAP SuccessFactors
              </div>
              <p className="Sap_P4_S1_P">Raising the standard with SAP SuccessFactors experience</p>
              {/* hover content SuccessFactors */}
              <Link to="/sap-SuccessFactors" className="hover-content">
                <div className="hover-content-Main">
                  <p>
                    Phoenix assists in implementing SAP SuccessFactors for building meaningful human-centered connections as employee and customer expectations
                    rise.
                  </p>
                  <Link to="/sap-SuccessFactors" className="hover-content-button">
                    <button>
                      explore
                      <LazyMedia type="image" src={SapArrowRight} alt="SapArrowRight" className="SapArrowRightButton" />
                    </button>
                  </Link>
                </div>
              </Link>
              {/* end hover content SuccessFactors */}
            </div>
            {/* Part 3 */}
            <div className="Sap_P4_S1_Main">
              <div className="Sap_P4_S1_Tag">
                <LazyMedia type="image" src={SapRectangle} alt="SapRectangle" />
                SAP Ariba
              </div>
              <p className="Sap_P4_S1_P">Enhance Your Procurement Efficiency with SAP Ariba</p>
              {/* hover content Ariba */}
              <Link to="/sap-ariba" className="hover-content">
                <div className="hover-content-Main">
                  <p>
                    Phoenix provides an ultimate solution through SAP Ariba, simplifying procurement, cutting costs, and improving collaboration with a
                    user-friendly interface and powerful tools.
                  </p>
                  <Link to="/sap-ariba" className="hover-content-button">
                    <button>
                      explore
                      <LazyMedia type="image" src={SapArrowRight} alt="SapArrowRight" className="SapArrowRightButton" />
                    </button>
                  </Link>
                </div>
              </Link>
              {/* hover content Ariba */}
            </div>
            {/* Part 4 */}
            <div className="Sap_P4_S1_Main">
              <div className="Sap_P4_S1_Tag">
                <LazyMedia type="image" src={SapRectangle} alt="SapRectangle" />
                RISE with SAP
              </div>
              <p className="Sap_P4_S1_P">Phoenix boosts RISE with SAP as a premium provider of cloud managed services.</p>
              {/* hover content RISE */}
              <Link to="/Sap-Rise" className="hover-content">
                <div className="hover-content-Main">
                  <p>
                    We have expanded its RISE with SAP services by introducing enhanced capabilities through Phoenix's Cloud Managed Services for RISE with SAP
                    clients.
                  </p>
                  <Link to="/Sap-Rise" className="hover-content-button">
                    <button>
                      explore
                      <LazyMedia type="image" src={SapArrowRight} alt="SapArrowRight" className="SapArrowRightButton" />
                    </button>
                  </Link>
                </div>
              </Link>
              {/* end hover content RISE */}
            </div>
          </div>
        </div>
        {/* end Sap_PartFour */}
        <div className="SectionSpace">
          <LazyMedia type="image" src={SectionSpace} alt="SectionSpace" />
        </div>
        <div className="Sap_PartFive">
          <h1>Our services</h1>
          {isMobile ? <SapACCMobile /> : <SapACC />}
        </div>
        <div className="SectionSpace">
          <LazyMedia type="image" src={SectionSpace} alt="SectionSpace" />
        </div>
        <div className="Sap_PartSixContainer">{/* <TestimonialSapSlider /> */}</div>
      </div>
    </>
  );
}

export default SapContent;
