import React from "react";
import "./industriesSubmenu.css";
import { ReactComponent as ArrowMenu } from "../../../assets/svg/arrow-right-submenu.svg";
import { ReactComponent as OilGas } from "../../../assets/svg/oil&gas.svg";
import { ReactComponent as Manufacturing } from "../../../assets/svg/Manufacturing.svg";
import { ReactComponent as Government } from "../../../assets/svg/Government.svg";
import { ReactComponent as Telecommunication } from "../../../assets/svg/Telecommunication.svg";
import { ReactComponent as ConstructionRealEstate } from "../../../assets/svg/ConstructionRealEstate.svg";
import { ReactComponent as InformationCommunication } from "../../../assets/svg/InformationCommunication.svg";
import { ReactComponent as FMCG } from "../../../assets/svg/FMCG.svg";
import { Link } from "react-router-dom";
// import { ReactComponent as Retail } from "../../../assets/svg/Retail.svg";
// import { ReactComponent as Healthcare } from "../../../assets/svg/Healthcare.svg";
// import { ReactComponent as Automotive } from "../../../assets/svg/Automotive.svg";

function IndustriesSubmenu() {
  return (
    <div className="submenu_industriesMain">
      <div className="submenu_contain_industries">
        <div className="submenu_info_industries">
          <p className="submenu_industries_name">Industries</p>
          <p className="submenu_discover_industries">Explore expert tech solutions</p>
          <p className="submenu_help_industries">We adapt with technology advancements to provide top-tier solutions in diverse sectors.</p>
          {/* <Link to={`${MainUrl}#`}>
            <button className="submenu_industries_button">
              Find your industry
            </button>
          </Link> */}
        </div>
        {/* buttons */}
        <div className="submenu_industries_contain">
          {/* button oil & gas */}
          <Link to="/Oils-gas" className="submenu_industries_link">
            <div className="submenu_industries_linkWithLogo">
              <OilGas className="industries_svg" />
              Oil & Gas
            </div>
            <ArrowMenu />
          </Link>
          {/* button Manufacturing */}
          <Link to="/Manufacturing" className="submenu_industries_link">
            <div className="submenu_industries_linkWithLogo">
              <Manufacturing className="industries_svg" />
              Manufacturing
            </div>
            <ArrowMenu />
          </Link>
          <Link to="/Government" className="submenu_industries_link">
            <div className="submenu_industries_linkWithLogo">
              <Government className="industries_svg" />
              Government
            </div>
            <ArrowMenu />
          </Link>
          {/* button Retail */}

          {/* <Link to={`${MainUrl}#`} className="submenu_industries_link">
            <div className="submenu_industries_linkWithLogo">
              <Retail className="industries_svg" />
              Retail
            </div>
            <ArrowMenu />
          </Link> */}
          {/* button Healthcare */}
          {/* <Link to={`${MainUrl}#`} className="submenu_industries_link">
            <div className="submenu_industries_linkWithLogo">
              <Healthcare className="industries_svg" />
              Healthcare
            </div>
            <ArrowMenu />
          </Link> */}
          {/* button Automotive */}
          {/* <Link to={`${MainUrl}#`} className="submenu_industries_link">
            <div className="submenu_industries_linkWithLogo">
              <Automotive className="industries_svg" />
              Automotive
            </div>
            <ArrowMenu />
          </Link> */}
        </div>
        {/* Start Right part */}
        <div className="submenu_industries_contain hidden">
          {/* button Government */}
          <Link to="/Government" className="submenu_industries_link">
            <div className="submenu_industries_linkWithLogo">
              <Government className="industries_svg" />
              Government
            </div>
            <ArrowMenu />
          </Link>
          {/* button Telecommunication */}
          <Link to="/" className="submenu_industries_link">
            <div className="submenu_industries_linkWithLogo">
              <Telecommunication className="industries_svg" />
              Telecommunication
            </div>

            <ArrowMenu />
          </Link>
          {/* button Construction & Real Estate */}
          <Link to="/" className="submenu_industries_link">
            <div className="submenu_industries_linkWithLogo">
              <ConstructionRealEstate className="industries_svg" />
              Construction & Real Estate
            </div>
            <ArrowMenu />
          </Link>
          {/* button Information & communication */}
          <Link to="/" className="submenu_industries_link">
            <div className="submenu_industries_linkWithLogo">
              <InformationCommunication className="industries_svg" />
              Information & communication
            </div>
            <ArrowMenu />
          </Link>
          {/* button FMCG */}
          <Link to="/" className="submenu_industries_link">
            <div className="submenu_industries_linkWithLogo">
              <FMCG className="industries_svg" />
              FMCG
            </div>
            <ArrowMenu />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default IndustriesSubmenu;
