import React from "react";
import "./MoreSubMenuMobile.css";
import { Link } from "react-router-dom";

function MoreSubmenuMobile() {
  return (
    <div className="submenuMobile_More_main">
      {/* Clients Page */}
      <Link to="/Clients" className="submenuMobile_More_link">
        <div className="submenuMobile_More_linkWithLogo">Clients</div>
      </Link>

      {/* Careers Page */}
      <Link to="/Careers" className="submenuMobile_More_link">
        <div className="submenuMobile_More_linkWithLogo">Careers</div>
      </Link>
      <Link to="/case-Studies" className="submenuMobile_More_link">
        <div className="submenuMobile_More_linkWithLogo">Case Studies</div>
      </Link>
    </div>
  );
}

export default MoreSubmenuMobile;
