import React from "react";
import "./Government.css";
import GovernmentP1S2 from "../../assets/svg/Government_P1_S2.svg";
import GovernmentP2S2Arab from "../../assets/svg/Government_P2_S2_Arab.svg";
import GovernmentP2S2Hayaa from "../../assets/svg/Government_P2_S2_Hayaa.svg";
import GovernmentP2S2ArabMin from "../../assets/svg/Government_P2_S2_ArabMin.svg";
import GovernmentP2S2Eastern from "../../assets/images/Government_P2_S2_Eastern.png";
import GovernmentP2S2Gehaz from "../../assets/images/Government_P2_S2_Gehaz.png";
import HomeP8L2Planning from "../../assets/svg/Home_P8_L2_Planning_and_Economic_deveopment.svg";

import Government_P2_HarasQatar from "../../assets/images/Clients_P2_HarasQatar.jpg";
import HomeP8L2Haras from "../../assets/svg/Home_P8_L2_Haras.svg";
import LazyMedia from "../../lazyLoad/lazyLoad";
function GovernmentContent() {
  return (
    <>
      <div className="Government_Container">
        <div className="Government_FirstPart">
          <div className="Government_P1_S1">
            <h1 className="Government_P1_S1_H1">Government & Public Sector</h1>
            <LazyMedia type="image" src={GovernmentP1S2} alt="GovernmentP1S2" className="GovernmentP1S2Mobile" />
            <p className="Government_P1_S1_P">
              In the modern world, communication, organization, and the demand for change are evolving at an unprecedented pace, Digital technologies are
              reshaping how people work, use services, and live, creating new patterns of citizen behavior. This poses challenges for all organizations,
              including governments, where digital technologies are essential for success. Phoenix assists governments in adopting digital technology to enhance
              citizen experiences and improve outcomes in customer service, public value, infrastructure, security, and workforce capabilities. We work
              alongside governments to create a better world for all citizens.
            </p>
          </div>
          <div className="Government_P1_S2">
            <LazyMedia type="image" src={GovernmentP1S2} alt="GovernmentP1S2" className="GovernmentP1S2Web" />
          </div>
        </div>
        <div className="Government_SecondPart">
          <div className="Government_P2_S1">
            <h1 className="Government_P2_S1_H1">Our Partners of success</h1>
          </div>
          <div className="Government_P2_S2">
            <div className="Government_P2_S2_L">
              <LazyMedia type="image" src={GovernmentP2S2Arab} alt="GovernmentP2S2Arab" />
              <LazyMedia type="image" src={GovernmentP2S2Hayaa} alt="GovernmentP2S2Hayaa" />
              <LazyMedia type="image" src={Government_P2_HarasQatar} alt="Government_P2_HarasQatar" className="Government_P2_HarasQatar" />
            </div>
            <div className="Government_P2_S2_L">
              <LazyMedia type="image" src={GovernmentP2S2ArabMin} alt="GovernmentP2S2ArabMin" />
              <LazyMedia type="image" src={GovernmentP2S2Eastern} alt="GovernmentP2S2Eastern" />
              <LazyMedia type="image" src={GovernmentP2S2Gehaz} alt="GovernmentP2S2Gehaz" />
            </div>
            <div className="Government_P2_S2_Last">
              <LazyMedia type="image" src={HomeP8L2Planning} alt="HomeP8L2Planning" />
              <LazyMedia type="image" src={HomeP8L2Haras} alt="HomeP8L2Haras" className="GovernmentL2Haras" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default GovernmentContent;
