import React from "react";
import "./Digital_Marketing.css";
import { Link } from "react-router-dom";
import LazyMedia from "../../lazyLoad/lazyLoad";
import DigitalBannerVideo from "../../assets/videos/Digital_Banner.mp4";
import P2_Icon_Tree from "../../assets/svg/Digital_P2_Icon_Tree.svg";
import P2_Icon_Tree2 from "../../assets/svg/Digital_P2_Icon_Tree2.svg";
import P2_Icon_Tree3 from "../../assets/svg/Digital_P2_Icon_Tree3.svg";
import P2_Line_Border from "../../assets/svg/Digital_P2_Line_Border.svg";
import Digital_P3_CrossHair from "../../assets/svg/Digital_P3_CrossHair.svg";
import Digital_P3_search from "../../assets/svg/Digital_P3_search.svg";
import Digital_P3_Move from "../../assets/svg/Digital_P3_Move.svg";
import Digital_P3_Facebook from "../../assets/svg/Digital_P3_Facebook.svg";
import Digital_P3_Mail from "../../assets/svg/Digital_P3_Mail.svg";
import Digital_P3_Oil from "../../assets/svg/Digital_P3_Oil.svg";
import Digital_P3_SmartPhone from "../../assets/svg/Digital_P3_SmartPhone.svg";
import Digital_P3_Whatsapp from "../../assets/svg/Digital_P3_Whatsapp.svg";
import Digital_P4_Polygon_Left from "../../assets/svg/Digital_P4_Polygon_Left.svg";
import Digital_P4_Polygon_LeftUp from "../../assets/svg/Digital_P4_Polygon_LeftUp.svg";
import Digital_P4_Polygon_Right from "../../assets/svg/Digital_P4_Polygon_Right.svg";
import Digital_P4_S2 from "../../assets/svg/Digital_P4_S2.svg";
import Digital_P5_S2Left from "../../assets/images/Digital_P5_S2Left.jpg";
import Digital_P5_S2Right from "../../assets/images/Digital_P5_S2Right.jpg";
import Digital_P5_Line_Border from "../../assets/svg/Digital_P5_Line_Border.svg";
import Digital_P6_box_Border from "../../assets/svg/Digital_P6_box_Border.svg";
import Digital_P7_S2 from "../../assets/images/Digital_P7_S2.jpg";

function DigitalContent() {
  return (
    <>
      <div className="Digital_Container">
        {/* part one */}
        <div className="Digital_FirstPart_Banner">
          <LazyMedia type="video" src={DigitalBannerVideo} className="DigitalBannerVideo" />
          <h1 className="Digital_P1_H1">Marketing Services</h1>
          <p className="Digital_P1_P">Elevate Your Brand: Digital Marketing Solutions for Visibility and Engagement</p>
        </div>
        {/* part two */}
        <div className="Digital_SecondPart">
          <div className="Digital_P2_Container">
            <div className="Digital_P2_S1_Head">
              <h1 className="Digital_P2_S1_Head_H1">Business transformation</h1>
            </div>
            <div className="Digital_P2_S2">
              {/* box 1 */}
              <div className="Digital_P2_S2_Box">
                <LazyMedia type="image" src={P2_Icon_Tree} alt="P2_Icon_Tree" className="P2_Icon_Tree" />
                <h3 className="Digital_P2_S2_Box_H3">Business Growth Strategy</h3>
                <p className="Digital_P2_S2_Box_P">Tailored strategies to foster business expansion.</p>
              </div>
              <LazyMedia type="image" src={P2_Line_Border} alt="P2_Line_Border" className="P2_Line_Border" />
              {/* box 2 */}
              <div className="Digital_P2_S2_Box">
                <LazyMedia type="image" src={P2_Icon_Tree2} alt="P2_Icon_Tree" className="P2_Icon_Tree" />
                <h3 className="Digital_P2_S2_Box_H3">Business Analysis</h3>
                <p className="Digital_P2_S2_Box_P">Comprehensive analysis to enhance business operations.</p>
              </div>
              <LazyMedia type="image" src={P2_Line_Border} alt="P2_Line_Border" className="P2_Line_Border" />
              {/* box 3 */}
              <div className="Digital_P2_S2_Box">
                <LazyMedia type="image" src={P2_Icon_Tree3} alt="P2_Icon_Tree" className="P2_Icon_Tree" />
                <h3 className="Digital_P2_S2_Box_H3">Marketing Strategy</h3>
                <p className="Digital_P2_S2_Box_P">Customized marketing plans for optimal results.</p>
              </div>
            </div>
          </div>
        </div>
        {/* part Three */}
        <div className="Digital_ThirdPart">
          <div className="Digital_P3_Head">Digital Marketing</div>
          <section className="Digital_P3_Main">
            {/* line 1 */}
            <div className="Digital_P3_Main_L">
              <div className="Digital_P3_Main_L_Part">
                <LazyMedia type="image" src={Digital_P3_CrossHair} alt="" className="Digital_P3_Main_L_Part_S1" />
                <div className="Digital_P3_Main_L_Part_S2">
                  <h3 className="Digital_P3_Main_L_Part_S2_H3">Digital Marketing Planning</h3>
                  <p className="Digital_P3_Main_L_Part_S2_P">Detailed plans to achieve online marketing goals</p>
                </div>
              </div>
              <div className="Digital_P3_Main_L_Part">
                <LazyMedia type="image" src={Digital_P3_search} alt="" className="Digital_P3_Main_L_Part_S1" />
                <div className="Digital_P3_Main_L_Part_S2">
                  <h3 className="Digital_P3_Main_L_Part_S2_H3">Search Engine Optimization (SEO)</h3>
                  <p className="Digital_P3_Main_L_Part_S2_P">Enhance online visibility and organic traffic.</p>
                </div>
              </div>
            </div>
            {/* line 2 */}
            <div>
              <div className="Digital_P3_Main_L">
                <div className="Digital_P3_Main_L_Part">
                  <LazyMedia type="image" src={Digital_P3_Move} alt="" className="Digital_P3_Main_L_Part_S1" />
                  <div className="Digital_P3_Main_L_Part_S2">
                    <h3 className="Digital_P3_Main_L_Part_S2_H3">Pay-Per-Click Advertising (PPC)</h3>
                    <p className="Digital_P3_Main_L_Part_S2_P">Targeted advertising for increased conversions.</p>
                  </div>
                </div>
                <div className="Digital_P3_Main_L_Part">
                  <LazyMedia type="image" src={Digital_P3_Facebook} alt="" className="Digital_P3_Main_L_Part_S1" />
                  <div className="Digital_P3_Main_L_Part_S2">
                    <h3 className="Digital_P3_Main_L_Part_S2_H3">Social Media Marketing</h3>
                    <p className="Digital_P3_Main_L_Part_S2_P">Engaging campaigns across social platforms.</p>
                  </div>
                </div>
              </div>
            </div>
            {/* line 3 */}
            <div>
              <div className="Digital_P3_Main_L">
                <div className="Digital_P3_Main_L_Part">
                  <LazyMedia type="image" src={Digital_P3_Mail} alt="" className="Digital_P3_Main_L_Part_S1" />
                  <div className="Digital_P3_Main_L_Part_S2">
                    <h3 className="Digital_P3_Main_L_Part_S2_H3">Email Marketing</h3>
                    <p className="Digital_P3_Main_L_Part_S2_P">Strategic email campaigns for customer engagement.</p>
                  </div>
                </div>
                <div className="Digital_P3_Main_L_Part">
                  <LazyMedia type="image" src={Digital_P3_Oil} alt="" className="Digital_P3_Main_L_Part_S1" />
                  <div className="Digital_P3_Main_L_Part_S2">
                    <h3 className="Digital_P3_Main_L_Part_S2_H3">E-commerce Marketing</h3>
                    <p className="Digital_P3_Main_L_Part_S2_P">Specialized marketing strategies for online stores.</p>
                  </div>
                </div>
              </div>
            </div>
            {/* line 4 */}
            <div>
              <div className="Digital_P3_Main_L">
                <div className="Digital_P3_Main_L_Part">
                  <LazyMedia type="image" src={Digital_P3_SmartPhone} alt="" className="Digital_P3_Main_L_Part_S1" />
                  <div className="Digital_P3_Main_L_Part_S2">
                    <h3 className="Digital_P3_Main_L_Part_S2_H3">SMS Campaigns</h3>
                    <p className="Digital_P3_Main_L_Part_S2_P">Targeted SMS campaigns for direct communication.</p>
                  </div>
                </div>
                <div className="Digital_P3_Main_L_Part">
                  <LazyMedia type="image" src={Digital_P3_Whatsapp} alt="" className="Digital_P3_Main_L_Part_S1" />
                  <div className="Digital_P3_Main_L_Part_S2">
                    <h3 className="Digital_P3_Main_L_Part_S2_H3">WhatsApp Campaigns</h3>
                    <p className="Digital_P3_Main_L_Part_S2_P">Engaging marketing through WhatsApp.</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        {/* part Four */}
        <div className="Digital_FourthPart">
          <div className="Digital_P4_S1">
            <div className="Digital_P4_S1_Container">
              {" "}
              {/* LazyMedia type="image" absolute */}
              <LazyMedia type="image" src={Digital_P4_Polygon_Left} alt="Digital_P4_Polygon_Left" className="Digital_P4_Polygon_Left" />
              <LazyMedia type="image" src={Digital_P4_Polygon_LeftUp} alt="Digital_P4_Polygon_LeftUp" className="Digital_P4_Polygon_LeftUp" />
              {/* content */}
              <h1 className="Digital_P4_S1_H1">Creative Services</h1>
              <ul className="Digital_P4_S1_Ul">
                <li className="Digital_P4_S1_Li">
                  <strong>Branding & Corporate Identity:</strong> Crafting brand identity for recognition.
                </li>
                <li className="Digital_P4_S1_Li">
                  <strong>Video Production (2D Animated Videos):</strong> Compelling animated videos for brand storytelling.
                </li>
                <li className="Digital_P4_S1_Li">
                  <strong>Graphic Designing:</strong> Creative visual elements for brand representation.
                </li>
                <li className="Digital_P4_S1_Li">
                  <strong>Photography:</strong> Captivating images to enhance brand aesthetics.
                </li>
                <li className="Digital_P4_S1_Li">
                  <strong>Videography:</strong> High-quality videos for various marketing needs.
                </li>
              </ul>
            </div>
          </div>
          <div className="Digital_P4_S2">
            <LazyMedia type="image" src={Digital_P4_S2} alt="Digital_P4_S2" className="Digital_P4_S2_Photo" />
            <LazyMedia type="image" src={Digital_P4_Polygon_Right} alt="Digital_P4_Polygon_Right" className="Digital_P4_Polygon_Right" />
          </div>
        </div>
        {/* part five */}
        <div className="Digital_FifthPart">
          <div className="Digital_P5_Head">Offline Activities</div>
          <div className="Digital_P5_S2">
            <div className="Digital_P5_S2_Left">
              <LazyMedia type="image" src={Digital_P5_S2Left} alt="Digital_P5_S2Left" className="Digital_P5_S2LeftPhoto" />
              <h2 className="Digital_P5_S2_H2">Print Media (Indoors and Outdoors): Strategic offline advertising for wider reach.</h2>
            </div>
            <div className="Digital_P5_S2_Right">
              <LazyMedia type="image" src={Digital_P5_S2Right} alt="Digital_P5_S2Right" className="Digital_P5_S2RightPhoto" />
              <h2 className="Digital_P5_S2_H2">Event Management and Sponsorships: Organizing impactful events and sponsorships.</h2>
            </div>
          </div>
          <div className="Digital_P5_S3">
            <p className="Digital_P5_S3_P">
              At Phoenix, we combine expertise with innovation to provide comprehensive digital marketing services. Our goal is to boost your brand's online
              presence, increase engagement, and drive business growth.
            </p>
          </div>
          <div className="Digital_P5_S4">
            <LazyMedia type="image" src={Digital_P5_Line_Border} alt="Digital_P5_Line_Border" />
          </div>
          <div className="Digital_P5_S5">
            <h3 className="Digital_P5_S5_H3">Ready to Elevate Your Brand's Presence Online?</h3>
            <p className="Digital_P5_S5_P">
              Contact Phoenix today and let our tailored digital marketing services propel your business to new heights. Whether you need SEO, engaging social
              media campaigns, innovative software solutions, or captivating creative services,
              <span className="Digital_P5_S5_Span">we've got you covered!</span>
            </p>
          </div>
          <div className="Digital_P5_S6">
            <LazyMedia type="image" src={Digital_P5_Line_Border} alt="Digital_P5_Line_Border" />
          </div>
        </div>
        {/* part six */}
        <div className="Digital_SixthPart">
          <div className="Digital_P6_S1">
            <h1 className="Digital_P6_S1_H1">The way we work</h1>
          </div>
          <div className="Digital_P6_S2">
            {/* box 1 */}
            <div className="Digital_P6_S2_Box">
              <div className="Digital_P6_S2_Box_S1">
                <LazyMedia type="image" src={Digital_P6_box_Border} alt="Digital_P6_box_Border" className="Digital_P6_box_Border" />
                <h3 className="Digital_P6_S2_Box_S1_H3">Simple is smart</h3>
              </div>
              <p className="Digital_P6_S2_Box_S2">
                We reject complexity, jargon and textbook approaches to branding. branding should be as straightforward as possible. we believe that simple
                ideas enter the brain faster.
              </p>
            </div>
            <LazyMedia type="image" src={P2_Line_Border} alt="P6_Line_Border" className="P6_Line_Border" />
            {/* box 2 */}
            <div className="Digital_P6_S2_Box">
              <div className="Digital_P6_S2_Box_S1">
                <LazyMedia type="image" src={Digital_P6_box_Border} alt="Digital_P6_box_Border" className="Digital_P6_box_Border" />
                <h3 className="Digital_P6_S2_Box_S1_H3">Built for speed</h3>
              </div>
              <p className="Digital_P6_S2_Box_S2">
                We deliver big brand agency experience at start-up speed. We work faster and smarter than any other agency out there.
              </p>
            </div>
            <LazyMedia type="image" src={P2_Line_Border} alt="P6_Line_Border" className="P6_Line_Border" />
            {/* box 3 */}
            <div className="Digital_P6_S2_Box">
              <div className="Digital_P6_S2_Box_S1">
                <LazyMedia type="image" src={Digital_P6_box_Border} alt="Digital_P6_box_Border" className="Digital_P6_box_Border3" />
                <h3 className="Digital_P6_S2_Box_S1_H3">Stand OUT FOrM CRowd</h3>
              </div>
              <p className="Digital_P6_S2_Box_S2_Box3">
                Our goal is to create great brands, but, in many ways, our jobs is far simpler: it’s to make you look good. Great work, great ideas delivered to
                be brutally effective and memorable.
              </p>
            </div>
          </div>
        </div>
        {/* part seven */}
        <div className="Digital_SeventhPart">
          <div className="Digital_P7_S1">
            <h1 className="Digital_P7_S1_H1">Reach out now to discuss your digital marketing needs and watch your brand thrive!</h1>
            <Link to="/Contact-us">
              <button className="Digital_P7_S1_Button">Contact Us</button>
            </Link>
          </div>
          <div className="Digital_P7_S2">
            <LazyMedia type="image" src={Digital_P7_S2} alt="Digital_P7_S2" className="Digital_P7_S2Photo" />
          </div>
        </div>
      </div>
    </>
  );
}

export default DigitalContent;
